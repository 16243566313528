import {SelectItem} from "../types/_general/SelectItem";
import {PaymentInterval} from "../types/paymentInterval/PaymentInterval";

export const getPaymentIntervalItems = (paymentIntervals: PaymentInterval[]): SelectItem[] => {
  const paymentIntervalItems: SelectItem[] = paymentIntervals.map((paymentInterval: PaymentInterval) => {
    const paymentIntervalItem: SelectItem = {
      value: paymentInterval.id,
      label: paymentInterval.intervalName,
    }
    return paymentIntervalItem;
  });
  return paymentIntervalItems;
}