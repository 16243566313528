import React from "react";
import useDeletePayment from "../../hooks/payment/deletePayment";
import Modal from "../../components/_general/Modal";
import Button from "../../components/_general/Button";
import Alert from "../../components/_general/Alert";
import useLabels from "../../hooks/useLabels";
import labels from "../../types/constants/labels";
import {Payment} from "../../types/payment/Payment";

interface Props {
  payment: Payment;
  showDeleteModal: boolean;
  onInitShowDeleteModal: (show: boolean) => void;
  onReloadPayments: () => void;
}

const DeletePaymentFragment = ({ payment, showDeleteModal,
                                onInitShowDeleteModal, onReloadPayments }: Props) => {

  const { getLabel: label } = useLabels();

  const {
    isLoading,
    hasError,
    alertData,
    deletePayment
  } = useDeletePayment();

  const handleDeletePayment = async () => {
    await deletePayment(payment);
    await onReloadPayments();
    onInitShowDeleteModal(false);
  }

  const handleCancel = () => {
    onInitShowDeleteModal(false);
  }

  return (
      <>
        <Modal id="deletePaymentForm"
               showModal={showDeleteModal}
               title={label(labels.payments.actions.DELETE_PAYMENT)}
               onCancel={handleCancel}>
          {alertData &&
              <Alert alertData={alertData} />
          }
          {payment &&
            <>
              <div className="modal-body">
                <p>{label(labels.payments.actions.REALLY_DELETE_PAYMENT, `${payment.paymentName}`)}</p>
              </div>
              <div className="modal-footer">
                <Button classes="btn-light" icon="x" label={label(labels.actions.CANCEL)}
                    onClick={() => { onInitShowDeleteModal(false) } } />
                <Button classes="btn-primary" icon="check" label={label(labels.actions.DELETE)} onClick={handleDeletePayment} />
              </div>
            </>
          }
        </Modal>
      </>
  );

}

export default DeletePaymentFragment;