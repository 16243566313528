import {useState} from "react";
import useFetch from "../useFetch";
import {createPostRequest} from "../../utils/apiUtils";
import {AlertData} from "../../types/components/AlertData";
import {createSuccessAlert, handleError} from "../../utils/appUtils";
import {pageActions} from "../../redux/slices/pageSlice";
import {useDispatch} from "react-redux";
import useLabels from "../useLabels";
import labels from "../../types/constants/labels";
import {PaymentCategory} from "../../types/paymentCategory/PaymentCategory";
import {createNewPaymentCategory} from "../../utils/paymentCategoryUtils";

const useNewPaymentCategory = () => {
  const { getLabel: label } = useLabels();
  const dispatch = useDispatch();

  // const navigate = useNavigate();

  const { isLoading, hasError, fetchData } = useFetch();

  const [paymentCategory, setPaymentCategory] = useState<PaymentCategory>(null);

  const [alertData, setAlertData] = useState<AlertData>(null);

  const resetPaymentCategory = () => {
    const newPaymentCategory: PaymentCategory = createNewPaymentCategory();
    setPaymentCategory(newPaymentCategory);
    setAlertData(null);
  }

  const saveNewPaymentCategory = async (paymentCategory: PaymentCategory): Promise<boolean> => {
    const paymentCategoryData = {
      paymentCategory: paymentCategory,
    }
    const reqData = createPostRequest(paymentCategoryData);
    const respData = await fetchData('/payment-category/create', reqData, true);

    const errorAlert: AlertData = handleError(respData, label);
    if (errorAlert) {
      setAlertData(errorAlert);
      return false;
    }
    const successAlert: AlertData = createSuccessAlert(label(labels.paymentCategories.alerts.ADDED_SUCCESSFULLY,
        `${paymentCategory.categoryName}`));
    dispatch(pageActions.initAlertData(successAlert));
    return true;
    // navigate(`/cards/${paymentCategory.cardId}`);
  }

  const cancelNewPaymentCategory = () => {
    // navigate(`/cards/${card.id}`);
  }

  return {
    isLoading,
    hasError,
    paymentCategory,
    alertData,
    resetPaymentCategory,
    saveNewPaymentCategory,
    cancelNewPaymentCategory,
  };

}

export default useNewPaymentCategory;