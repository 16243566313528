import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {PaymentsState} from "../../types/state/PaymentsState";
import {PaymentMode} from "../../types/paymentMode/PaymentMode";
import {PaymentType} from "../../types/paymentType/PaymentType";
import {PaymentInterval} from "../../types/paymentInterval/PaymentInterval";
import {PaymentCategory} from "../../types/paymentCategory/PaymentCategory";

const initialState: PaymentsState = {
  paymentTypes: [],
  paymentModes: [],
  paymentIntervals: [],
  paymentCategories: [],
}

const initPaymentTypes = (paymentsState: PaymentsState, action: PayloadAction<PaymentType[]>) => {
  paymentsState.paymentTypes = action.payload;
}

const initPaymentModes = (paymentsState: PaymentsState, action: PayloadAction<PaymentMode[]>) => {
  paymentsState.paymentModes = action.payload;
}

const initPaymentIntervals = (paymentsState: PaymentsState, action: PayloadAction<PaymentInterval[]>) => {
  paymentsState.paymentIntervals = action.payload;
}

const initPaymentCategories = (paymentsState: PaymentsState, action: PayloadAction<PaymentCategory[]>) => {
  paymentsState.paymentCategories = action.payload;
}


const clear = (paymentsState: PaymentsState) => {
  paymentsState.paymentTypes = [];
  paymentsState.paymentModes = [];
  paymentsState.paymentIntervals = [];
  paymentsState.paymentCategories = [];
}

const paymentsSlice = createSlice({
  name: 'payments',
  initialState,
  reducers: {
    initPaymentTypes,
    initPaymentModes,
    initPaymentIntervals,
    initPaymentCategories,
    clear,
  },
});

export const paymentsReducer = paymentsSlice.reducer;
export const paymentsActions = paymentsSlice.actions;