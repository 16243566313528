import React, {useEffect} from "react";
import PaymentForm from "../../components/payment/paymentEdit/PaymentForm";
import useEditPayment from "../../hooks/payment/editPayment";
import {Payment} from "../../types/payment/Payment";
import {PaymentsState} from "../../types/state/PaymentsState";
import {useSelector} from "react-redux";

interface Props {
  paymentId: number;
  showEditModal: boolean;
  onInitShowEditModal: (show: boolean) => void;
  onReloadPayments: () => void;
}

const EditPaymentFragment = ({ paymentId, showEditModal,
                              onInitShowEditModal, onReloadPayments }: Props) => {

  const { paymentTypes }: PaymentsState = useSelector((state: any) => state.payments);
  const { paymentModes }: PaymentsState = useSelector((state: any) => state.payments);
  const { paymentIntervals }: PaymentsState = useSelector((state: any) => state.payments);
  const { paymentCategories }: PaymentsState = useSelector((state: any) => state.payments);

  const {
    isLoading, hasError,
    payment, alertData,
    loadPayment,
    saveEditPayment,
    cancelEditPayment
  } = useEditPayment();

  useEffect(() => {
    (async () => {
      if (paymentId) {
        await loadPayment(`${paymentId}`);
      }
    })();
  }, [paymentId]);

  const handleSavePayment = async (payment: Payment): Promise<boolean> => {
    const wasSucess: boolean = await saveEditPayment(payment);
    if (!wasSucess) {
      return wasSucess;
    }
    await onReloadPayments();
    return wasSucess;
  }

  const handleCancelPayment = () => {
    cancelEditPayment();
  }

  return (
      <>
        <PaymentForm payment={payment}
                     paymentTypes={paymentTypes}
                     paymentCategories={paymentCategories}
                     paymentModes={paymentModes}
                     paymentIntervals={paymentIntervals}
                     alertData={alertData}
                     mode="EDIT"
                     modalId="editPaymentForm"
                     showModal={showEditModal}
                     isLoading={isLoading}
                     hasError={hasError}
                     onInitShowModal={onInitShowEditModal}
                     onSavePayment={handleSavePayment}
                     onCancel={handleCancelPayment} />
      </>
  );

}

export default EditPaymentFragment;