import React, {useEffect} from "react";
import {useForm} from "react-hook-form";
import Modal from "../../_general/Modal";
import Alert from "../../_general/Alert";
import {AlertData} from "../../../types/components/AlertData";
import Button from "../../_general/Button";
import {Payment} from "../../../types/payment/Payment";
import useLabels from "../../../hooks/useLabels";
import labels from "../../../types/constants/labels";
import {convertToPayment, getPaymentStatusItems} from "../../../utils/paymentUtils";
import {SelectItem} from "../../../types/_general/SelectItem";
import {PaymentType} from "../../../types/paymentType/PaymentType";
import {PaymentCategory} from "../../../types/paymentCategory/PaymentCategory";
import {PAYMENT_MODE_RECURRING, PaymentMode} from "../../../types/paymentMode/PaymentMode";
import {PaymentInterval} from "../../../types/paymentInterval/PaymentInterval";
import {getPaymentTypeItems} from "../../../utils/paymentTypeUtils";
import {getPaymentCategoryItems} from "../../../utils/paymentCategoryUtils";
import {getPaymentModeItems} from "../../../utils/paymentModeUtils";
import {getPaymentIntervalItems} from "../../../utils/paymentIntervalUtils";

interface Props {
  payment: Payment;
  paymentTypes: PaymentType[];
  paymentCategories: PaymentCategory[];
  paymentModes: PaymentMode[];
  paymentIntervals: PaymentInterval[];
  alertData: AlertData;
  mode: string;
  modalId: string;
  showModal: boolean;
  isLoading: boolean;
  hasError: boolean;
  onInitShowModal: (show: boolean) => void;
  onSavePayment: (payment: Payment) => Promise<boolean>;
  onCancel: () => void;
}

const PaymentForm = ({ payment, paymentTypes, paymentCategories, paymentModes, paymentIntervals,
                       alertData, mode, modalId, showModal,
                       isLoading, hasError, onInitShowModal, onSavePayment, onCancel }: Props) => {

  const { getLabel: label } = useLabels();

  const {
    register,
    handleSubmit: onSubmit,
    watch,
    formState: { errors },
    getValues,
    reset
  } = useForm<Payment>();

  const paymentModeId: number = Number(watch("paymentModeId"));

  const paymentTypeItems: SelectItem[] = getPaymentTypeItems(paymentTypes);
  const paymentCategoryItems: SelectItem[] = getPaymentCategoryItems(paymentCategories);
  const paymentModeItems: SelectItem[] = getPaymentModeItems(paymentModes);
  const paymentIntervalsItems: SelectItem[] = getPaymentIntervalItems(paymentIntervals);
  const paymentStatusItems: SelectItem[] = getPaymentStatusItems();

  useEffect(() => {
    reset(payment);
  }, [payment]);


  const handleSubmit = async (formPayment: Payment) => {
    const payment: Payment = convertToPayment(formPayment);

    const wasSuccess: boolean = await onSavePayment(payment);
    if (wasSuccess) {
      onInitShowModal(false);
    }

  }

  const handleCancel = () => {
    onCancel();
    onInitShowModal(false);
  }

  return (
      <>
          <Modal id={modalId} showModal={showModal}
                 title={mode === 'EDIT' ? label(labels.payments.actions.EDIT_PAYMENT) :
                          label(labels.payments.actions.NEW_PAYMENT)}
                 scrollable={true}
                 onCancel={handleCancel}>
            {isLoading &&
              <p>{label(labels.payments.titles.PAYMENT_LOADED)}</p>
            }

            {mode === 'EDIT' && !payment &&
              <p>{label(labels.payments.titles.NO_PAYMENT_FOUND)}</p>
            }

            {alertData &&
                <Alert alertData={alertData} />
            }

            {payment &&
              <form onSubmit={onSubmit(handleSubmit)}>
                <div className="modal-body">

                  {mode === 'EDIT' &&
                      <input type="hidden" id={`${modalId}-id`}
                             {...register("id", {})}
                             defaultValue={payment.id}
                             className="form-control"/>
                  }

                  <div className="form-group mb-3">
                    <label htmlFor={`${modalId}-paymentDateFormatted`} className="form-label">
                      {label(labels.payments.fields.PAYMENT_DATE) + ' *'}
                    </label>
                    <input type="date" id={`${modalId}-paymentDateFormatted`}
                           {...register("paymentDateFormatted", {required: true})}
                           className={`form-control ${errors.paymentDateFormatted ? 'is-invalid' : ''}`}
                           placeholder={label(labels.payments.fields.PAYMENT_DATE)} required/>
                    {errors.paymentDateFormatted &&
                        <div className="invalid-feedback">
                          {label(labels.payments.fields.PAYMENT_DATE_INVALID)}
                        </div>
                    }
                  </div>

                  <div className="form-group mb-3">
                    <label htmlFor={`${modalId}-paymentName`} className="form-label">
                      {label(labels.payments.fields.PAYMENT_NAME) + ' *'}
                    </label>
                    <input type="text" id={`${modalId}-paymentName`}
                           {...register("paymentName", {required: true, maxLength: 255, pattern: /^(?! ).*(?<! )$/})}
                           className={`form-control ${errors.paymentName ? 'is-invalid' : ''}`}
                           placeholder={label(labels.payments.fields.PAYMENT_NAME)}
                           required maxLength={255}/>
                    {errors.paymentName &&
                        <div className="invalid-feedback">
                          {label(labels.payments.fields.PAYMENT_NAME_INVALID)}
                        </div>
                    }
                  </div>

                  <div className="form-group mb-3">
                    <label htmlFor={`${modalId}-amount`} className="form-label">
                      {label(labels.payments.fields.AMOUNT) + ' *'}
                    </label>
                    <input type="number" id={`${modalId}-amount`}
                           {...register("amount", {required: true, min: 0, max: 10000000})}
                           className={`form-control ${errors.amount ? 'is-invalid' : ''}`}
                           placeholder={label(labels.payments.fields.AMOUNT)}
                           required min={0} max={10000000} step={0.01}/>
                    {errors.amount &&
                        <div className="invalid-feedback">
                          {label(labels.payments.fields.AMOUNT_INVALID)}
                        </div>}
                  </div>

                  <div className="form-group mb-3">
                    <label htmlFor={`${modalId}-paymentCategoryId`} className="form-label">
                           {label(labels.payments.fields.PAYMENT_CATEGORY) + ' *'}
                    </label>
                    <select id={`${modalId}-paymentCategoryId`} className="form-select"
                            {...register("paymentCategoryId")} >
                      {paymentCategoryItems.map((item: SelectItem, i: number) => (
                          <option key={item.value}
                                  value={item.value}>
                            {item.label}
                          </option>
                      ))}
                    </select>
                    {errors.paymentCategoryId &&
                        <div className="invalid-feedback">
                          {label(labels.payments.fields.PAYMENT_CATEGORY_INVALID)}
                        </div>
                    }
                  </div>

                  <div className="form-group mb-3">
                    <label htmlFor={`${modalId}-paymentTypeId`} className="form-label">
                      {label(labels.payments.fields.PAYMENT_TYPE) + ' *'}
                    </label>
                    <select id={`${modalId}-paymentTypeId`} className="form-select"
                            {...register("paymentTypeId")} >
                      {paymentTypeItems.map((item: SelectItem, i: number) => (
                          <option key={item.value}
                                  value={item.value}>
                            {item.label}
                          </option>
                      ))}
                    </select>
                    {errors.paymentTypeId &&
                        <div className="invalid-feedback">
                          {label(labels.payments.fields.PAYMENT_TYPE_INVALID)}
                        </div>
                    }
                  </div>

                  <div className="form-group mb-3">
                    <label htmlFor={`${modalId}-paymentModeId`} className="form-label">
                      {label(labels.payments.fields.PAYMENT_MODE) + ' *'}
                    </label>
                    <select id={`${modalId}-paymentModeId`} className="form-select"
                            disabled={mode === 'EDIT'}
                            {...register("paymentModeId")} >
                      {paymentModeItems.map((item: SelectItem, i: number) => (
                          <option key={item.value}
                                  value={item.value}>
                            {item.label}
                          </option>
                      ))}
                    </select>
                    {errors.paymentModeId &&
                        <div className="invalid-feedback">
                          {label(labels.payments.fields.PAYMENT_MODE_INVALID)}
                        </div>
                    }
                  </div>

                  {paymentModeId === PAYMENT_MODE_RECURRING &&
                      <div className="form-group mb-3">
                        <label htmlFor={`${modalId}-paymentIntervalId`} className="form-label">
                          {label(labels.payments.fields.PAYMENT_INTERVAL) + ' *'}
                        </label>
                        <select id={`${modalId}-paymentIntervalId`} className="form-select"
                                {...register("paymentIntervalId")} >
                          {paymentIntervalsItems.map((item: SelectItem, i: number) => (
                              <option key={item.value}
                                      value={item.value}>
                                {item.label}
                              </option>
                          ))}
                        </select>
                        {errors.paymentIntervalId &&
                            <div className="invalid-feedback">
                              {label(labels.payments.fields.PAYMENT_INTERVAL_INVALID)}
                            </div>
                        }
                      </div>
                  }

                  {paymentModeId === PAYMENT_MODE_RECURRING &&
                    <div className="form-group mb-3">
                      <label htmlFor={`${modalId}-intervalValue`} className="form-label">
                        {label(labels.payments.fields.INTERVAL_VALUE) + ' *'}
                      </label>
                      <input type="number" id={`${modalId}-intervalValue`}
                             {...register("intervalValue", {required: true, min: 1, max: 31})}
                             className={`form-control ${errors.intervalValue ? 'is-invalid' : ''}`}
                             placeholder={label(labels.payments.fields.INTERVAL_VALUE)}
                             required min={1} max={31} step={1}/>
                      {errors.intervalValue &&
                          <div className="invalid-feedback">
                            {label(labels.payments.fields.INTERVAL_VALUE_INVALID)}
                          </div>}
                    </div>
                  }

                  <div className="form-group mb-3">
                    <label htmlFor={`${modalId}-statusId`} className="form-label">
                      {label(labels.payments.fields.STATUS) + ' *'}
                    </label>
                    <select id={`${modalId}-statusId`} className="form-select"
                            {...register("statusId")} >
                      {paymentStatusItems.map((item: SelectItem, i: number) => (
                          <option key={item.value}
                                  value={item.value}>
                            {label(item.label)}
                          </option>
                      ))}
                    </select>
                    {errors.statusId &&
                        <div className="invalid-feedback">
                          {label(labels.payments.fields.STATUS_INVALID)}
                        </div>
                    }
                  </div>


                  <div className="form-check mb-3">
                    <input type="checkbox" id={`${modalId}-deductible`}
                           {...register("deductible")}
                           className={`form-check-input ${errors.deductible ? 'is-invalid' : ''}`}/>
                    <label htmlFor={`${modalId}-deductible`} className="form-check-label">
                            {label(labels.payments.fields.DEDUCTIBLE)}
                    </label>
                  </div>

                  {mode === 'EDIT' && paymentModeId === PAYMENT_MODE_RECURRING &&
                    <>
                      <hr className="divider mb-3 mt-3" />

                      <div className="form-check mb-3">
                        <input type="checkbox" id={`${modalId}-updateFollowingPayments`}
                               {...register("updateFollowingPayments")}
                               className={`form-check-input ${errors.deductible ? 'is-invalid' : ''}`}/>
                        <label htmlFor={`${modalId}-updateFollowingPayments`} className="form-check-label">
                          {label(labels.payments.fields.UPDATE_FOLLOWING_PAYMENTS)}
                        </label>
                      </div>
                    </>
                  }


                </div>
                <div className="modal-footer">
                  <Button classes="btn-light mr-2" icon="x" label={label(labels.actions.CANCEL)}
                          onClick={handleCancel}/>
                  <Button type="submit" classes="btn-primary" icon="check" label={label(labels.actions.SAVE)}/>
                </div>
              </form>
            }
          </Modal>
      </>
  );
}

export default PaymentForm;