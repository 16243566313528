import PaymentItem from "./PaymentItem";
import Spinner from "../../_general/Spinner";
import React from "react";
import useLabels from "../../../hooks/useLabels";
import labels from "../../../types/constants/labels";
import {Payment} from "../../../types/payment/Payment";
import List from "../../_general/List";
import {PaymentStatus} from "../../../types/payment/PaymentStatus";

interface Props {
  payments: Payment[];
  paymentStatus: PaymentStatus;
  isLoading: boolean;
  onInitShowEditModal: (show: boolean) => void;
  onInitShowDeleteModal: (show: boolean) => void;
  onInitEditPayment: (payment: Payment) => void;
  onInitDeletePayment: (payment: Payment) => void;
  onUpdatePaymentStatus: (payment: Payment, paymentStatus: PaymentStatus) => void;
}
const PaymentList = ({ payments, paymentStatus, isLoading,
                     onInitShowEditModal, onInitShowDeleteModal, onInitEditPayment, onInitDeletePayment, onUpdatePaymentStatus }: Props) => {

  const { getLabel: label } = useLabels();

  if (isLoading) {
    return (
        <>
          <Spinner />
          <p className="text-muted mb-4">
            {paymentStatus == PaymentStatus.PENDING ?
                label(labels.payments.titles.PENDING_PAYMENTS_LOADED) :
                label(labels.payments.titles.PLACED_PAYMENTS_LOADED)
            }
          </p>
        </>
    );
  }

  if (!payments || payments.length === 0) {
    return (
        <p className="text-muted mb-4">
          {paymentStatus == PaymentStatus.PENDING ?
            label(labels.payments.titles.NO_PENDING_PAYMENTS_FOUND) :
            label(labels.payments.titles.NO_PLACED_PAYMENTS_FOUND)
          }
        </p>
    );
  }

  return (
      <>
        <List classes="mb-3">
          {payments.map((payment: Payment) => (
              <PaymentItem
                  key={payment.id}
                  payment={payment}
                  paymentStatus={paymentStatus}
                  onInitShowEditModal={onInitShowEditModal}
                  onInitShowDeleteModal={onInitShowDeleteModal}
                  onInitEditPayment={onInitEditPayment}
                  onInitDeletePayment={onInitDeletePayment}
                  onUpdatePaymentStatus={onUpdatePaymentStatus}
              />
          ))}
        </List>
      </>
      );

}

export default PaymentList;