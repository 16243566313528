import {configureStore} from '@reduxjs/toolkit';
import {authReducer} from "./slices/authSlice";
import {pageReducer} from "./slices/pageSlice";
import {loadState, saveState} from "../utils/storageUtils";
import {paymentsReducer} from "./slices/paymentsSlice";

const persistedState: any = loadState();

const storeOptions: any = {
  reducer: {
    auth: authReducer,
    page: pageReducer,
    payments: paymentsReducer,
  },
};

if (persistedState) {
  storeOptions.preloadedState = persistedState;
}

const reduxStore = configureStore(storeOptions);

reduxStore.subscribe(() => {
  saveState(reduxStore.getState());
});

export default reduxStore;