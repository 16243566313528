import "./PaymentCategoryColor.scss";

interface Props {
  color: String;
}
const PaymentCategoryColor = ({ color }: Props) => {


  return (
      <>
        <div className="color-circle" style={{backgroundColor: `#${color}`}}></div>
      </>
  );
}

export default PaymentCategoryColor;