import "./NavMenu.scss";
import {NavLink} from "react-router-dom";
import useLabels from "../../hooks/useLabels";
import labels from "../../types/constants/labels";
import Icon from "../_general/Icon";

interface Props {
  isLoggedIn: boolean;
}

const NavMenu = ({ isLoggedIn }: Props) => {

  const { getLabel: label } = useLabels();


  return (
      <aside className="nav-menu">
        {isLoggedIn &&
            <ul className="menu">

              <li className="menu-item">
                <NavLink to="/payments"
                         className={({isActive}) => (isActive ? 'menu-item-link active' : 'menu-item-link')}>
                  <Icon icon="currency-euro"/>
                  <span>
                    {label(labels.nav.PAYMENTS)}
                  </span>
                </NavLink>
              </li>

              <li className="menu-item">
                <NavLink to="/stats"
                         className={({isActive}) => (isActive ? 'menu-item-link active' : 'menu-item-link')}>
                  <Icon icon="chart-pie"/>
                  <span>
                    {label(labels.nav.STATS)}
                  </span>
                </NavLink>
              </li>

              <li className="menu-item">
                <NavLink to="/payment-categories"
                         className={({isActive}) => (isActive ? 'menu-item-link active' : 'menu-item-link')}>
                  <Icon icon="tag"/>
                  <span>
                    {label(labels.nav.PAYMENT_CATEGORIES)}
                  </span>
                </NavLink>
              </li>
            </ul>
        }
      </aside>
  );
}
export default NavMenu;