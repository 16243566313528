import Button from "../../_general/Button";
import "./PaymentCategoryRow.scss";
import {PaymentCategory} from "../../../types/paymentCategory/PaymentCategory";
import {format} from "date-fns";
import DATE_FORMATS from "../../../types/constants/dateFormats";
import PaymentCategoryColor from "./PaymentCategoryColor";

interface Props {
  paymentCategory: PaymentCategory;
  onInitShowEditModal: (show: boolean) => void;
  onInitShowDeleteModal: (show: boolean) => void;
  onInitEditPaymentCategory: (paymentCategory: PaymentCategory) => void;
  onInitDeletePaymentCategory: (paymentCategory: PaymentCategory) => void;
}
const PaymentCategoryRow = ({ paymentCategory,
                     onInitShowEditModal, onInitShowDeleteModal, onInitEditPaymentCategory, onInitDeletePaymentCategory }: Props) => {

  const createDateAsDate: Date = new Date(paymentCategory.createDate);
  const changeDateAsDate: Date = new Date(paymentCategory.changeDate);

  const handleEditClick = () => {
    onInitShowEditModal(true);
    onInitEditPaymentCategory(paymentCategory);
  }

  const handleDeleteClick = () => {
    onInitShowDeleteModal(true);
    onInitDeletePaymentCategory(paymentCategory);
  }

  return (
      <>
        <tr>
          <td>
            #{paymentCategory.id}
          </td>
          <td>{paymentCategory.categoryName}</td>
          <td>
            {paymentCategory.color &&
              <PaymentCategoryColor color={paymentCategory.color} />
            }
          </td>
          <td>{paymentCategory.createDate &&
              format(createDateAsDate, DATE_FORMATS.DATE_DE)}</td>

          <td>{paymentCategory.changeDate &&
              format(changeDateAsDate, DATE_FORMATS.DATE_DE)}</td>
          <td className="text-end">
            <Button classes="btn-light btn-sm me-2" icon="pencil"
                    onClick={handleEditClick}
            />
            <Button classes="btn-light btn-sm" icon="trash"
                    onClick={handleDeleteClick}
            />
          </td>
        </tr>
      </>
  );
}

export default PaymentCategoryRow;