import React, {useCallback} from "react";
import "./Tag.scss";

interface Props {
  label: string;
  color: string;
}
const Tag = ({ label, color }: Props) => {
  return (
      <>
        <span className={`tag`} style={{backgroundColor: color}}>
          {label}
        </span>
      </>
  );
}

export default Tag;