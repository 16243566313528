import {AuthState} from "../../types/state/AuthState";
import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import {BreacrumbData} from "../../types/components/BreacrumbData";
import Alert from "../../components/_general/Alert";
import Breadcrumb from "../../components/_general/Breadcrumb";
import {PageState} from "../../types/state/PageState";
import Button from "../../components/_general/Button";
import {pageActions} from "../../redux/slices/pageSlice";
import useLabels from "../../hooks/useLabels";
import labels from "../../types/constants/labels";
import PaymentCategoriesTable from "../../components/paymentCategory/paymentCategoryList/PaymentCategoriesTable";
import NewPaymentCategoryFragment from "../../pageFragments/paymentCategory/NewPaymentCategoryFragment";
import EditPaymentCategoryFragment from "../../pageFragments/paymentCategory/EditPaymentCategoryFragment";
import DeletePaymentCategoryFragment from "../../pageFragments/paymentCategory/DeletePaymentCategoryFragment";
import usePaymentCategoryList from "../../hooks/paymentCategory/paymentCategoryList";
import {PaymentCategory} from "../../types/paymentCategory/PaymentCategory";

const PaymentPaymentCategoryListPage = () => {
  const { getLabel: label } = useLabels();
  const dispatch = useDispatch();

  const { isLoggedIn }: AuthState = useSelector((state: any) => state.auth);
  const { alertData: globalAlertData }: PageState = useSelector((state: any) => state.page);

  const [selectedPaymentCategoryId, setSelectedPaymentCategoryId] = useState<number>(null);
  const [selectedPaymentCategory, setSelectedPaymentCategory] = useState<PaymentCategory>(null);

  const [showNewModal, setShowNewModal] = useState<boolean>(false);
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

  const {
    isLoading,
    hasError,
    alertData,
    paymentCategories,
    loadPaymentCategories,
  } = usePaymentCategoryList();

  const breadcrumbPages: BreacrumbData[] = [{ item: label(labels.paymentCategories.titles.CATEGORIES), to: '/payment-categories'}];

  useEffect(() => {
    (async () => {
      await loadPaymentCategories();
    })();
  }, []);

  useEffect(() => {
    let timer = setTimeout(() => {
        dispatch(pageActions.clear());
    }, 5 * 1000);
    return () => {
        clearTimeout(timer);
    };
  }, [globalAlertData]);

  const initShowNewModal = (show: boolean) => {
    setShowNewModal(show);
  }

  const initShowEditModal = (show: boolean) => {
    setShowEditModal(show);
  }

  const initShowDeleteModal = (show: boolean) => {
    setShowDeleteModal(show);
  }

  const initEditPaymentCategory = (paymentCategory: PaymentCategory) => {
    setSelectedPaymentCategoryId(paymentCategory ? paymentCategory.id : null);
  }

  const initDeletePaymentCategory = (paymentCategory: PaymentCategory) => {
    setSelectedPaymentCategory(paymentCategory);
  }


  const handleNewClick = () => {
    initShowNewModal(true);
  }

  if (!isLoggedIn) {
    return;
  }

  return (
      <>
        {(globalAlertData ?? alertData) &&
            <Alert alertData={globalAlertData ?? alertData} />
        }

        <Breadcrumb pages={breadcrumbPages} />

        <div className="main-header mb-2">
          <h4>{label(labels.paymentCategories.titles.CATEGORIES)}</h4>
          <div>
            <Button classes="btn-primary" icon="plus" label={label(labels.actions.ADD)}
                    onClick={handleNewClick} />
          </div>
        </div>

        {paymentCategories &&
          <PaymentCategoriesTable paymentCategories={paymentCategories}
                         isLoading={isLoading}
                          onInitShowEditModal={initShowEditModal}
                          onInitShowDeleteModal={initShowDeleteModal}
                          onInitEditPaymentCategory={initEditPaymentCategory}
                          onInitDeletePaymentCategory={initDeletePaymentCategory}/>
        }

        <NewPaymentCategoryFragment showNewModal={showNewModal}
                            onInitShowNewModal={initShowNewModal}
                            onReloadPaymentCategories={loadPaymentCategories} />

        <EditPaymentCategoryFragment paymentCategoryId={selectedPaymentCategoryId}
                             showEditModal={showEditModal}
                             onInitShowEditModal={initShowEditModal}
                             onReloadPaymentCategories={loadPaymentCategories} />

        <DeletePaymentCategoryFragment paymentCategory={selectedPaymentCategory}
                              showDeleteModal={showDeleteModal}
                              onInitShowDeleteModal={initShowDeleteModal}
                              onReloadPaymentCategories={loadPaymentCategories} />



      </>
  );
}

export default PaymentPaymentCategoryListPage;