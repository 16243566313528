import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {BreacrumbData} from "../../types/components/BreacrumbData";
import labels from "../../types/constants/labels";
import useLabels from "../../hooks/useLabels";
import Breadcrumb from "../../components/_general/Breadcrumb";
import {Payment} from "../../types/payment/Payment";
import Alert from "../../components/_general/Alert";
import Button from "../../components/_general/Button";
import NewPaymentFragment from "../../pageFragments/payment/NewPaymentFragment";
import EditPaymentFragment from "../../pageFragments/payment/EditPaymentFragment";
import DeletePaymentFragment from "../../pageFragments/payment/DeletePaymentFragment";
import {PageState} from "../../types/state/PageState";
import {pageActions} from "../../redux/slices/pageSlice";
import PaymentList from "../../components/payment/paymentList/PaymentList";
import usePaymentList from "../../hooks/payment/paymentList";
import {PaymentStatus} from "../../types/payment/PaymentStatus";
import PaymentPagination from "../../components/payment/paymentList/PaymentPagination";
import PaymentSummary from "../../components/payment/paymentList/PaymentSummary";
import {PAYMENT_TYPE_INCOME} from "../../types/paymentType/PaymentType";
import {
  calcTotalExpenses,
  calcTotalIncome,
  getPendingPaymentsByMonth,
  getPlacedPaymentsByMonth
} from "../../utils/paymentUtils";
import "./StatsPage.scss";
import PaymentFilter from "../../components/payment/paymentList/PaymentFilter";
import {PaymentFilterValue} from "../../types/payment/PaymentFilterValue";
import {addMonths, format} from "date-fns";
import {getMonthsOfYear} from "../../utils/dateUtils";
import DATE_FORMATS from "../../types/constants/dateFormats";
import {de} from "date-fns/locale";

const StatsPage = () => {
  const { getLabel: label } = useLabels();
  const dispatch = useDispatch();

  const { alertData: globalAlertData }: PageState = useSelector((state: any) => state.page);

  // const { projectTypes }: ProjectsState = useSelector((state: any) => state.projects);

  const {
    isLoading,
    hasError,
    date,
    filterValue,
    pendingPayments,
    placedPayments,
    alertData,
    initDate,
    filter,
    loadPayments,
  } = usePaymentList();

  const breadcrumbPages: BreacrumbData[] = [{ item: label(labels.stats.titles.STATS), to: '/stats'},];

  const income = calcTotalIncome(pendingPayments, placedPayments);
  const expenses = calcTotalExpenses(pendingPayments, placedPayments);
  const balance = income + expenses;

  useEffect(() => {
    initDate();
  }, []);

  useEffect(() => {
    (async () => {
      if (date && filterValue) {
        await loadPayments();
      }
    })();
  }, [date, filterValue]);

  useEffect(() => {
    let timer = setTimeout(() => {
      dispatch(pageActions.clear());
    }, 5 * 1000);
    return () => {
      clearTimeout(timer);
    };
  }, [globalAlertData]);


  return (
      <>
        {(globalAlertData ?? alertData) &&
            <Alert alertData={globalAlertData ?? alertData}/>
        }

        <Breadcrumb pages={breadcrumbPages}/>

        <div className="main-header mb-3">
          <h4>{label(labels.stats.titles.STATS)}</h4>
        </div>

        <div className="payment-list-header mb-4">
          <div>
            <PaymentFilter filterValue={filterValue}
                           onFilter={filter} />
            <PaymentPagination date={date}
                               filterValue={filterValue}
                               onInitDate={initDate} />
          </div>
        </div>
      </>
      );
}

export default StatsPage;