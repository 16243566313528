import {useState} from "react";
import useFetch from "../useFetch";
import {createPostRequest} from "../../utils/apiUtils";
import {AlertData} from "../../types/components/AlertData";
import {createSuccessAlert, handleError} from "../../utils/appUtils";
import {useNavigate, useSearchParams} from "react-router-dom";
import {pageActions} from "../../redux/slices/pageSlice";
import {useDispatch} from "react-redux";
import {Payment} from "../../types/payment/Payment";
import useLabels from "../useLabels";
import labels from "../../types/constants/labels";
import {initPayment} from "../../utils/paymentUtils";

const useEditPayment = () => {
  const { getLabel: label } = useLabels();

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const { isLoading, hasError, fetchData, checkForAuthTokenError } = useFetch();

  const [payment, setPayment] = useState<Payment>(null);

  const [alertData, setAlertData] = useState<AlertData>(null);


  const loadPayment = async (paymentId: string) => {
    const resp = await fetchData(`/payment/${paymentId}`, null, true);
    checkForAuthTokenError(resp);
    const errorAlert: AlertData = handleError(resp, label);
    if (errorAlert) {
      setAlertData(errorAlert);
    } else if (resp.payment) {
      const { payment } = resp;
      initPayment(payment);
      setPayment(payment);
    }
  }

  const saveEditPayment = async (payment: Payment) => {
    const paymentData = {
      payment: payment,
    }
    const reqData = createPostRequest(paymentData);
    const respData = await fetchData('/payment/update', reqData, true);

    const errorAlert: AlertData = handleError(respData, label);
    if (errorAlert) {
      setAlertData(errorAlert);
      return false;
    }
    const successAlert: AlertData = createSuccessAlert(label(labels.payments.alerts.EDITED_SUCCESSFULLY,
        `${payment.paymentName}`));
    dispatch(pageActions.initAlertData(successAlert));
    return true;
    // navigate(`/cards/${payment.cardId}`);
  }

  const cancelEditPayment = () => {
    // navigate(`/cards/${card.id}`);
  }

  return {
    isLoading,
    hasError,
    payment,
    alertData,
    loadPayment,
    saveEditPayment,
    cancelEditPayment,
  };

}

export default useEditPayment;