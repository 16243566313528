const labels: any = {
  nav: {
    LOGIN: { de: 'Login', en: 'Login', },
    HOME: { de: 'Home', en: 'Home', },
    PAYMENTS: { de: 'Zahlungen', en: 'Payments', },
    STATS: { de: 'Statistiken', en: 'Stats', },
    PAYMENT_CATEGORIES: { de: 'Kategorien', en: 'Categories', },
    ACCOUNT: { de: 'Account', en: 'Account', },
    SWITCH_LANGUAGE: { de: '{0}', en: '{0}'},
    LOGOUT: { de: 'Logout', en: 'Logout', },
  },
  actions: {
    ADD: { de: 'Hinzufügen', en: 'Add'},
    CANCEL: { de: 'Abbrechen', en: 'Cancel'},
    SAVE: { de: 'Speichern', en: 'Save'},
    EDIT: { de: 'Bearbeiten', en: 'Edit' },
    DELETE: { de: 'Löschen', en: 'Delete' },
    IMPORT: { de: 'Importieren', en: 'Import' },
    EXPORT: { de: 'Exportieren', en: 'Export' },
  },
  errors: {
    ERROR_OCCURRED: { de: 'Es ist ein Fehler aufgetreten.', en: 'An error occurred.',},
    FETCH_ERROR: { de: 'Es ist ein Fehler beim Laden aufgetreten.', en: 'An error occurred while fetching data.',},
    UNKNOWN_ERROR: { de: 'Es ist ein unbekannter Fehler aufgetreten.', en: 'An unknown error occurred.',},
  },
  // LOGIN
  login: {
    fields: {
      USERNAME: {de: 'Benutzername', en: 'Username'},
      USERNAME_INVALID: {de: 'Benutzername ungültig.', en: 'Username invalid.'},
      PASSWORD: {de: 'Passwort', en: 'Password'},
      PASSWORD_INVALID: {de: 'Passwort ungültig.', en: 'Password invalid.'},
    },
    titles: {
      LOGIN: {de: 'Login', en: 'Login'},
    },
    actions: {
      LOGIN: {de: 'Anmelden', en: 'Login'},
    },
    errors: {
      LOGGED_OUT: {
        de: 'Sie wurden ausgeloggt. Bitte erneut anmelden.',
        en: 'You\' have been logged out. Please log in again.'
      },
      INVALID_USERNAME_PASSWORD: {
        de: 'Ungültiger Benutzername oder Passwort.',
        en: 'Invalid username or password.',
      },
      USER_BLOCKED: {
        de: 'Benutzer wurde gesperrt.',
        en: 'User has been blocked.',
      },
    }
  },
  // PAYMENT CATEGORIES
  paymentCategories: {
    fields: {
      ID: { de: 'ID', en: 'ID', },
      CATEGORY_NAME: { de: 'Bezeichnung', en: 'Name', },
      CATEGORY_NAME_INVALID: { de: 'Bezeichnung ungültig.', en: 'Name invalid.', },
      COLOR: { de: 'Farbe', en: 'Color', },
      COLOR_INVALID: { de: 'Farbe ungültig.', en: 'Color invalid.', },
      CREATE_DATE: { de: 'Erstellt am', en: 'Created at', },
      CHANGE_DATE: { de: 'Geändert am', en: 'Changed at', },
    },
    titles: {
      CATEGORIES: {de: 'Kategorien', en: 'Categories'},
      CATEGORIES_LOADED: {de: 'Kategorien werden geladen.'},
      NO_CATEGORIES_FOUND: {de: 'Keine Kategorien gefunden.'},
      CATEGORY_LOADED: {de: 'Kategorie wird geladen.'},
      NO_CATEGORY_FOUND: {de: 'Keine Kategorie gefunden.'},
    },
    actions: {
      NEW_CATEGORY: { de: 'Neue Kategorie', en: 'New Category'},
      EDIT_CATEGORY: { de: 'Kategorie bearbeiten', en: 'Edit Category'},
      DELETE_CATEGORY: { de: 'Kategorie löschen', en: 'Delete Category'},
      REALLY_DELETE_CATEGORY: {
        de: 'Möchten Sie die Kategorie "{0}" wirklich löschen?',
        en: 'Do you really want to delete the category "{0}"?'},
    },
    alerts: {
      ADDED_SUCCESSFULLY: {
        de: 'Die Kategorie "{0}" wurde erfolgreich angelegt.',
        en: 'The category "{0}" has been added successfully.'
      },
      EDITED_SUCCESSFULLY: {
        de: 'Die Kategorie "{0}" wurde erfolgreich bearbeitet.',
        en: 'The category "{0}" has been edited successfully.'
      },
      DELETED_SUCCESSFULLY: {
        de: 'Die Kategorie "{0}" wurde erfolgreich gelöscht.',
        en: 'The category "{0}" has been deleted successfully.'
      },
    }
  },

  // PAYMENTS
  payments: {
    fields: {
      ID: { de: 'ID', en: 'ID', },
      PAYMENT_NAME: { de: 'Bezeichnung', en: 'Name', },
      PAYMENT_NAME_INVALID: { de: 'Bezeichnung ungültig.', en: 'Name invalid.', },
      PAYMENT_DATE: { de: 'Datum', en: 'Date', },
      PAYMENT_DATE_INVALID: { de: 'Datum ungültig.', en: 'Date invalid.', },
      AMOUNT: { de: 'Betrag', en: 'Amount', },
      AMOUNT_INVALID: { de: 'Betrag ungültig.', en: 'Amount invalid.', },
      DEDUCTIBLE: { de: 'Absetzbar', en: 'Deductible', },
      INTERVAL_VALUE: { de: 'Tag des Monats', en: 'Day of month', },
      INTERVAL_VALUE_INVALID: { de: 'Tag des Monats ungültig.', en: 'Day of month invalid.', },
      PAYMENT_TYPE: { de: 'Typ', en: 'Type', },
      PAYMENT_TYPE_INVALID: { de: 'Typ ungültig.', en: 'Type invalid.', },
      PAYMENT_CATEGORY: { de: 'Kategorie', en: 'Category', },
      PAYMENT_CATEGORY_INVALID: { de: 'Kategorie ungültig.', en: 'Category invalid.', },
      PAYMENT_MODE: { de: 'Modus', en: 'Mode', },
      PAYMENT_MODE_INVALID: { de: 'Modus ungültig.', en: 'Mode invalid.', },
      PAYMENT_INTERVAL: { de: 'Intervall', en: 'Interval', },
      PAYMENT_INTERVAL_INVALID: { de: 'Intervall ungültig.', en: 'Interval invalid.', },
      STATUS: { de: 'Status', en: 'Status', },
      STATUS_INVALID: { de: 'Status ungültig.', en: 'Status invalid.', },
      CREATE_DATE: { de: 'Erstellt am', en: 'Created at', },
      CREATE_USER: { de: 'Erstellt von', en: 'Created by', },
      UPDATE_FOLLOWING_PAYMENTS: { de: 'Zukünftige Zahlungen aktualisieren', en: 'Update future payments', },
    },
    titles: {
      PAYMENTS: {de: 'Zahlungen', en: 'Payments'},
      PLACED_PAYMENTS_LOADED: {de: 'Getätigte Zahlungen werden geladen.'},
      NO_PLACED_PAYMENTS_FOUND: {de: 'Keine getätigten Zahlungen gefunden.'},
      PENDING_PAYMENTS_LOADED: {de: 'Bevorstehende Zahlungen werden geladen.'},
      NO_PENDING_PAYMENTS_FOUND: {de: 'Keine bevorstehenden Zahlungen gefunden.'},
      PAYMENT_LOADED: {de: 'Zahlung wird geladen.'},
      NO_PAYMENT_FOUND: {de: 'Keine Zahlung gefunden.'},
    },
    filter: {
      MONTH: {de: 'Monat', en: 'Month'},
      YEAR: {de: 'Jahr', en: 'Year'},
    },
    summary: {
      INCOME: {de: 'Einnahmen', en: 'Income'},
      EXPENSES: {de: 'Ausgaben', en: 'Expenses'},
    },
    actions: {
      NEW_PAYMENT: { de: 'Neue Zahlung', en: 'New Payment'},
      EDIT_PAYMENT: { de: 'Zahlung bearbeiten', en: 'Edit Payment'},
      DELETE_PAYMENT: { de: 'Zahlung löschen', en: 'Delete Payment'},
      REALLY_DELETE_PAYMENT: {
        de: 'Möchten Sie die Zahlung "{0}" wirklich löschen?',
        en: 'Do you really want to delete the payment "{0}"?'},
    },
    status: {
      PLACED: { de: 'Getätigt', en: 'Placed'},
      PENDING: { de: 'Bevorstehend', en: 'Pending'},
    },
    alerts: {
      ADDED_SUCCESSFULLY: {
        de: 'Die Zahlung "{0}" wurde erfolgreich angelegt.',
        en: 'The payment "{0}" has been added successfully.'
      },
      EDITED_SUCCESSFULLY: {
        de: 'Die Zahlung "{0}" wurde erfolgreich bearbeitet.',
        en: 'The payment "{0}" has been edited successfully.'
      },
      DELETED_SUCCESSFULLY: {
        de: 'Die Zahlung "{0}" wurde erfolgreich gelöscht.',
        en: 'The payment "{0}" has been deleted successfully.'
      },
    }
  },

  // STATS
  stats: {
    titles: {
      STATS: {de: 'Statistiken', en: 'Stats'},
      PLACED_PAYMENTS_LOADED: {de: 'Getätigte Zahlungen werden geladen.'},
      NO_PLACED_PAYMENTS_FOUND: {de: 'Keine getätigten Zahlungen gefunden.'},
      PENDING_PAYMENTS_LOADED: {de: 'Bevorstehende Zahlungen werden geladen.'},
      NO_PENDING_PAYMENTS_FOUND: {de: 'Keine bevorstehenden Zahlungen gefunden.'},
      PAYMENT_LOADED: {de: 'Zahlung wird geladen.'},
      NO_PAYMENT_FOUND: {de: 'Keine Zahlung gefunden.'},
    },
    filter: {
      MONTH: {de: 'Monat', en: 'Month'},
      YEAR: {de: 'Jahr', en: 'Year'},
    },
    summary: {
      INCOME: {de: 'Einnahmen', en: 'Income'},
      EXPENSES: {de: 'Ausgaben', en: 'Expenses'},
    },
  }
}

export default labels;