import "./Footer.scss";

const Footer = () => {
  return (
      <footer className="footer">
        <p className="text-small text-muted mb-0">
          by Peter
        </p>
      </footer>
  );
}
export default Footer;