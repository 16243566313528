import React from 'react';

import {RouterProvider} from "react-router-dom";
import appRouter from "./router/appRouter";
import reduxStore from "./redux/reduxStore";
import {Provider} from "react-redux";

function App() {

  return (
      <Provider store={reduxStore}>
        <RouterProvider router={appRouter} />
      </Provider>

  );
}

export default App;
