import React, {useEffect, useState} from "react";
import "./PaymentSummary.scss";
import {formatCurrency} from "../../../utils/formatUtils";
import useLabels from "../../../hooks/useLabels";
import labels from "../../../types/constants/labels";

interface Props {
  income: number;
  expenses: number;
  balance: number;
}

const PaymentSummary = ({ income, expenses, balance }: Props) => {

  const { getLabel: label } = useLabels();

  return (
      <>
        <div className="payment-summary">
          <p className="payment-summary-label">{label(labels.payments.summary.INCOME)}</p>
          <div className="payment-total-income">
            {formatCurrency(income)}
          </div>
          <p className="payment-summary-label">{label(labels.payments.summary.EXPENSES)}</p>
          <div className="payment-total-expenses">
            {formatCurrency(expenses)}
          </div>
          <p className="payment-summary-label"></p>
          <div className={`payment-balance ${balance >= 0.0 ? 'profit' : 'loss'}`}>
            {formatCurrency(balance)}
          </div>
        </div>
      </>
  );
}

export default PaymentSummary;