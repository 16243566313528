import useFetch from "../useFetch";
import {createPostRequest} from "../../utils/apiUtils";
import {AlertData} from "../../types/components/AlertData";
import {createSuccessAlert, handleError} from "../../utils/appUtils";
import {pageActions} from "../../redux/slices/pageSlice";
import {useDispatch} from "react-redux";
import useLabels from "../useLabels";
import {Payment} from "../../types/payment/Payment";
import {PaymentStatus} from "../../types/payment/PaymentStatus";

const useUpdatePaymentStatus = () => {
  const { getLabel: label } = useLabels();
  const dispatch = useDispatch();

  const { isLoading, hasError, fetchData } = useFetch();

  const updatePaymentStatus = async (payment: Payment, paymentStatus: PaymentStatus) => {
    const paymentStatusData = {
      id: payment.id,
      statusId: paymentStatus,
    }
    const reqData = createPostRequest(paymentStatusData);
    const respData = await fetchData('/payment/update/status', reqData, true);

    const errorAlert: AlertData = handleError(respData, label);
    if (errorAlert) {
      dispatch(pageActions.initAlertData(errorAlert));
      return;
    }

    // const successAlert: AlertData = createSuccessAlert(label(labels.testCases.alerts.EDITED_SUCCESSFULLY,
    //     `${testCase.caseNumber} ${testCase.caseName}`));
    // dispatch(pageActions.initAlertData(successAlert));
    // navigate(`/cards/${testCase.cardId}`);
  }

  return {
    isLoading,
    hasError,
    updatePaymentStatus,
  };

}

export default useUpdatePaymentStatus;