import "./Header.scss";

interface Props {
  children: any;
}


const Header = ({ children }: Props) => {
  return (
      <>
        <header className="header">
          {children}
        </header>
      </>
  );
}
export default Header;