import {useRouteError} from "react-router-dom";
import NavBar from "../../components/_layout/NavBar";
import labels from "../../types/constants/labels";
import useLabels from "../../hooks/useLabels";
import {AuthState} from "../../types/state/AuthState";
import {useSelector} from "react-redux";
import Footer from "../../components/_layout/Footer";
import useLogin from "../../hooks/login/login";
import useNav from "../../hooks/nav/nav";
import Header from "../../components/_layout/Header";
import Logo from "../../components/_layout/Logo";
import NavMenu from "../../components/_layout/NavMenu";

const ErrorPage = () => {
  const { getLabel: label } = useLabels();

  const { isLoggedIn, userData, language }: AuthState = useSelector((state: any) => state.auth);
  // const { projects, project, team }: ProjectsState = useSelector((state: any) => state.projects);

  const {
    logout,
  } = useLogin();
  const {
    // changeProject,
    // changeTeam,
    changeLanguage
  } = useNav();

  const error = useRouteError();
  console.error(error);

  const title = label(labels.errors.ERROR_OCCURRED);

  // const teams: Team[] = project?.teams ?? [];

  return (
      <>
        <div className="app-container">
          <Header>
            <Logo/>
            <NavBar isLoggedIn={isLoggedIn}
                    userData={userData}
                    language={language}
                    onLogout={logout}
                    onChangeLanguage={changeLanguage}/>
          </Header>

          <NavMenu isLoggedIn={isLoggedIn}/>

          <div className="main-wrapper">
            <main className="main">
              <div className="text-danger">{title}</div>
            </main>

            <Footer/>
          </div>
        </div>

      </>
  );
};

export default ErrorPage;