import {LoginData} from "../../types/user/LoginData";
import {useForm} from "react-hook-form";
import useLabels from "../../hooks/useLabels";
import labels from "../../types/constants/labels";
import Button from "../_general/Button";

interface Props {
  isLoading: boolean;
  onLogin: (loginData: LoginData) => void;
}
const LoginForm = ({ isLoading, onLogin }: Props) => {

  const { getLabel: label } = useLabels();

  const {
    register,
    handleSubmit: onSubmit,
    watch,
    formState: { errors },
    reset
  } = useForm<LoginData>();


  const handleSubmit = (formLoginData: LoginData) => {

    onLogin(formLoginData);

    reset({
      ...formLoginData,
      password: '',
    });
  }

  return (
      <>
        <div className="grid grid--3-cols">
          <div>
            <h3>{label(labels.login.titles.LOGIN)}</h3>

            <form onSubmit={onSubmit(handleSubmit)}>

              <div className="form-group mb-3">
                <label htmlFor="username" className="form-label">
                  {label(labels.login.fields.USERNAME)}
                </label>
                <input type="text" id="username" {...register("username", { required: true, maxLength: 255, pattern: /^(?! ).*(?<! )$/ })}
                       className={`form-control ${errors.username ? 'is-invalid' : ''}`} placeholder={label(labels.login.fields.USERNAME)} required />
                {errors.username &&
                  <div className="invalid-feedback">
                    {label(labels.login.fields.USERNAME_INVALID)}
                  </div>}
              </div>

              <div className="form-group mb-4">
                <label htmlFor="password" className="form-label">
                  {label(labels.login.fields.PASSWORD)}
                </label>
                <input type="password" id="password" {...register("password", { required: true, maxLength: 255, pattern: /^(?! ).*(?<! )$/ })}
                       className={`form-control ${errors.password ? 'is-invalid' : ''}`} placeholder={label(labels.login.fields.PASSWORD)} required />
                {errors.password &&
                    <div className="invalid-feedback">
                      {label(labels.login.fields.PASSWORD_INVALID)}
                    </div>}
              </div>

              <Button classes="btn btn-primary"
                      type="submit"
                      label={label(labels.login.actions.LOGIN)}
                      disabled={isLoading} />

            </form>
          </div>
        </div>
      </>
  );
};

export default LoginForm;