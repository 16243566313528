import {useCallback, useState} from "react";
import {addAuthData} from "../utils/apiUtils";
import {useDispatch, useSelector} from "react-redux";
import {AuthState} from "../types/state/AuthState";
import {UserData} from "../types/user/UserData";
import {ERROR_CODES} from "../types/constants/errorCodes";
import {authActions} from "../redux/slices/authSlice";
import {useNavigate} from "react-router-dom";

const useFetch = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const authState: AuthState = useSelector((state: any) => state.auth);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);

  const apiUrl: string = process.env.REACT_APP_REST_API_URL;
  const fetchData = useCallback(async (path: string, reqData: any, authRequired: boolean, userData?: UserData): Promise<any> => {
    setIsLoading(true);
    setHasError(false);

    const url = `${apiUrl}${path}`;
    const req = {
      method: reqData?.method ? reqData.method : 'GET',
      headers: reqData?.headers ? reqData.headers : {},
      body: reqData?.body ? JSON.stringify(reqData.body) : null,
    }
    if (authRequired) {
      addAuthData(req.headers, userData ?  userData : authState.userData);
    }
    try {
      const resp: Response = await fetch(url, req);

      if (!resp.ok && resp.status !== 422 && resp.status !== 401) {
        setHasError(true);

        console.log('Resp Status: ' + resp.status);
        console.error('An error occurred while fetching data.')
      }

      const respData = await resp.json();

      // console.log(respData);
      return respData;

    } catch (error) {
      console.error('An error occurred while fetching data.');
      console.error(error);

      setHasError(true);
      return null;

    } finally {
      setIsLoading(false);
    }


  }, []);

  const checkForAuthTokenError = (resp: any) => {
    if (!resp) {
      return;
    }
    if (resp.errorCode === ERROR_CODES.LOGIN_INVALID_AUTH_TOKEN) {
      dispatch(authActions.logout());
      navigate('/login?eventType=logged_out');
    }
  }

  return {
    isLoading,
    hasError,
    fetchData,
    checkForAuthTokenError,
  };

};

export default useFetch;