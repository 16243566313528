import {AlertData} from "../../types/components/AlertData";
import "./Alert.scss";

interface Props {
  alertData: AlertData;
}
const Alert = ({ alertData }: Props) => {
  return (
      <div className={`alert alert-${alertData.type} mb-4`} role="alert">
        {alertData.message}
      </div>
  );
}

export default Alert;