import {SelectItem} from "../types/_general/SelectItem";
import {PaymentMode} from "../types/paymentMode/PaymentMode";

export const getPaymentModeItems = (paymentModes: PaymentMode[]): SelectItem[] => {
  const paymentModeItems: SelectItem[] = paymentModes.map((paymentMode: PaymentMode) => {
    const paymentModeItem: SelectItem = {
      value: paymentMode.id,
      label: paymentMode.modeName,
    }
    return paymentModeItem;
  });
  return paymentModeItems;
}