import {PaymentCategory} from "../types/paymentCategory/PaymentCategory";
import {SelectItem} from "../types/_general/SelectItem";

export const createNewPaymentCategory = (): PaymentCategory => {
  const paymentCategory: PaymentCategory = {
    id: null,
    categoryName: null,
    color: null,
  };
  return paymentCategory;
}

export const initEditPaymentCategory = (paymentCategory: PaymentCategory) => {
  paymentCategory.color = paymentCategory.color ?? null;
}

export const convertToPaymentCategory = (formPaymentCategory: PaymentCategory): PaymentCategory => {

  const {
    id,
    categoryName,
    color,
  } = formPaymentCategory;

  const paymentCategory: PaymentCategory = {
    id: id ?? null,
    categoryName: categoryName || null,
    color: color || null,
  }
  return paymentCategory;
}

export const sortPaymentCategories = (paymentCategories: PaymentCategory[]) => {
  const paymentCategoriesSorted: PaymentCategory[] = [...paymentCategories].sort((a, b) => {
    if ( a.categoryName < b.categoryName ){
      return -1;
    }
    if ( a.categoryName > b.categoryName ){
      return 1;
    }
    return 0;
  });
  return paymentCategoriesSorted;
}

export const getPaymentCategoryItems = (paymentCategories: PaymentCategory[]): SelectItem[] => {
  const paymentCategoryItems: SelectItem[] = paymentCategories.map((paymentCategory: PaymentCategory) => {
    const paymentCategoryItem: SelectItem = {
      value: paymentCategory.id,
      label: paymentCategory.categoryName,
    }
    return paymentCategoryItem;
  });
  return paymentCategoryItems;
}