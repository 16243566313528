import "./Logo.scss";
import {Link, NavLink} from "react-router-dom";
import React from "react";
import CONSTANTS from "../../types/constants/constants";

const Logo = () => {

  const appTitle = CONSTANTS.APP_TITLE;

  return (
      <div className="logo-box">
        <Link to="/payments" className="logo text-color-gradient">{appTitle}</Link>
      </div>
  );
}
export default Logo;