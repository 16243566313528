import {useState} from "react";
import useFetch from "../useFetch";
import {createPostRequest} from "../../utils/apiUtils";
import {AlertData} from "../../types/components/AlertData";
import {createSuccessAlert, handleError} from "../../utils/appUtils";
import {pageActions} from "../../redux/slices/pageSlice";
import {useDispatch} from "react-redux";
import useLabels from "../useLabels";
import labels from "../../types/constants/labels";
import {Payment} from "../../types/payment/Payment";
import {createNewPayment} from "../../utils/paymentUtils";
import {PaymentCategory} from "../../types/paymentCategory/PaymentCategory";

const useNewPayment = () => {
  const { getLabel: label } = useLabels();
  const dispatch = useDispatch();

  // const navigate = useNavigate();

  const { isLoading, hasError, fetchData } = useFetch();

  const [payment, setPayment] = useState<Payment>(null);

  const [alertData, setAlertData] = useState<AlertData>(null);

  const resetPayment = (paymentCategories: PaymentCategory[], defaultDate: Date) => {
    const newPayment: Payment = createNewPayment(paymentCategories, defaultDate);
    setPayment(newPayment);
    setAlertData(null);
  }

  const saveNewPayment = async (payment: Payment): Promise<boolean> => {
    const paymentData = {
      payment: payment,
    }
    const reqData = createPostRequest(paymentData);
    const respData = await fetchData('/payment/create', reqData, true);

    const errorAlert: AlertData = handleError(respData, label);
    if (errorAlert) {
      setAlertData(errorAlert);
      return false;
    }
    const successAlert: AlertData = createSuccessAlert(label(labels.payments.alerts.ADDED_SUCCESSFULLY,
        `${payment.paymentName}`));
    dispatch(pageActions.initAlertData(successAlert));
    return true;
    // navigate(`/cards/${payment.cardId}`);
  }

  const cancelNewPayment = () => {
    // navigate(`/cards/${card.id}`);
  }

  return {
    isLoading,
    hasError,
    payment,
    alertData,
    resetPayment,
    saveNewPayment,
    cancelNewPayment,
  };

}

export default useNewPayment;