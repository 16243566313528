import "./List.scss";

interface Props {
  children: any;
  classes?: string;
}
const List = ({ children, classes }: Props) => {
  return (
      <ul className={`list ${classes ? classes : ''}`}>
        {children}
      </ul>
  );
}

export default List;