import "./NavBar.scss";
import {NavLink} from "react-router-dom";
import Icon from "../_general/Icon";
import labels from "../../types/constants/labels";
import useLabels from "../../hooks/useLabels";
import {UserData} from "../../types/user/UserData";
import React from "react";

interface Props {
  isLoggedIn: boolean;
  userData: UserData;
  // projects: Project[];
  // project: Project;
  // teams: Team[];
  // team: Team;
  language: string;
  onLogout: () => void;
  // onChangeProject: (project: Project) => void;
  // onChangeTeam: (team: Team) => void;
  onChangeLanguage: () => void;
}

const NavBar = ({ isLoggedIn, userData,  language, onLogout,
                   onChangeLanguage}: Props) => {

  const { getLabel: label } = useLabels();


  return (
        <nav className={`navbar navbar-expand-lg bg-light`} data-bs-theme="light">
          {!isLoggedIn &&
              <ul className="navbar-nav">
                <li className="nav-item">
                  <NavLink to="/"
                           className={({isActive}) => (isActive ? 'active nav-link' : 'nav-link')}>
                    {label(labels.nav.LOGIN)}
                  </NavLink>
                </li>
              </ul>
          }
          {isLoggedIn &&
            <>
              <ul className="navbar-nav">
                {/*<li className="nav-item">*/}
                {/*  <NavLink to="/home"*/}
                {/*           className={({isActive}) => (isActive ? 'active nav-link' : 'nav-link')}>*/}
                {/*    {label(labels.nav.HOME)}*/}
                {/*  </NavLink>*/}
                {/*</li>*/}
                <li className="nav-item">
                  <NavLink to="/account"
                           className={({isActive}) => (isActive ? 'active nav-link' : 'nav-link')}>
                    {label(labels.nav.ACCOUNT)}
                  </NavLink>
                </li>
                {/*<li className="nav-item">*/}
                {/*  <NavLink to="/sprints"*/}
                {/*           className={({isActive}) => (isActive ? 'active nav-link' : 'nav-link')}>*/}
                {/*    {label(labels.nav.SPRINTS)}*/}
                {/*  </NavLink>*/}
                {/*</li>*/}
                {/*{hasFeature(FEATURES.EVENTS, userData) &&*/}
                {/*    <li className="nav-item">*/}
                {/*      <NavLink to="/events"*/}
                {/*               className={({isActive}) => (isActive ? 'active nav-link' : 'nav-link')}>*/}
                {/*        {label(labels.nav.EVENTS)}*/}
                {/*      </NavLink>*/}
                {/*    </li>*/}
                {/*}*/}
                {/*{hasFeature(FEATURES.TIME_RECORDS, userData) &&*/}
                {/*    <li className="nav-item">*/}
                {/*      <NavLink to="/times"*/}
                {/*               className={({isActive}) => (isActive ? 'active nav-link' : 'nav-link')}>*/}
                {/*        {label(labels.nav.TIME_RECORDS)}*/}
                {/*      </NavLink>*/}
                {/*    </li>*/}
                {/*}*/}
                {/*{hasFeature(FEATURES.PROJECTS, userData) &&*/}
                {/*    <li className="nav-item">*/}
                {/*      <NavLink to="/projects"*/}
                {/*               className={({isActive}) => (isActive ? 'active nav-link' : 'nav-link')}>*/}
                {/*        {label(labels.nav.PROJECTS)}*/}
                {/*      </NavLink>*/}
                {/*    </li>*/}
                {/*}*/}
              </ul>

              <ul className="navbar-nav" data-bs-theme="light">
                {/*{projects && projects.length > 0 &&*/}
                {/*    <li className="nav-item dropdown">*/}
                {/*      <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown"*/}
                {/*       aria-expanded="false">*/}
                {/*      {project?.projectName}*/}
                {/*    </a>*/}
                {/*    <ul className="dropdown-menu">*/}
                {/*      {projects.map((project: Project) => (*/}
                {/*          <li key={project.id}>*/}
                {/*            <a className="dropdown-item" role="button" onClick={() => onChangeProject(project)}>*/}
                {/*              {project.projectName}*/}
                {/*            </a>*/}
                {/*          </li>*/}
                {/*      ))}*/}
                {/*    </ul>*/}
                {/*  </li>*/}
                {/*}*/}
                {/*{teams && teams.length > 0 &&*/}
                {/*  <li className="nav-item dropdown">*/}
                {/*    <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown"*/}
                {/*       aria-expanded="false">*/}
                {/*      {team?.teamName}*/}
                {/*    </a>*/}
                {/*    <ul className="dropdown-menu">*/}
                {/*      {teams.map((team: Team) => (*/}
                {/*          <li key={team.id}>*/}
                {/*            <a className="dropdown-item" role="button" onClick={() => onChangeTeam(team)}>*/}
                {/*              {team.teamName}*/}
                {/*            </a>*/}
                {/*          </li>*/}
                {/*      ))}*/}
                {/*    </ul>*/}
                {/*  </li>*/}
                {/*}*/}

                <li className="nav-item dropdown">
                  <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown"
                     aria-expanded="false">
                    <Icon icon="user-circle" />
                  </a>
                  <ul className="dropdown-menu">
                    <li>
                      <a className="dropdown-item" role="button" onClick={onChangeLanguage}>
                        {label(labels.nav.SWITCH_LANGUAGE, language === 'DE' ? 'EN' : 'DE')}
                      </a>
                    </li>
                    <li>
                      <hr className="dropdown-divider"/>
                    </li>
                    <li><a className="dropdown-item" role="button" onClick={onLogout}>
                      {label(labels.nav.LOGOUT)}
                    </a></li>
                  </ul>
                </li>
              </ul>
            </>
          }
        </nav>
  );
}
export default NavBar;