import React, {useEffect, useState} from "react";
import {addMonths, format} from "date-fns";
import Button from "../../_general/Button";
import DATE_FORMATS from "../../../types/constants/dateFormats";
import "./PaymentPagination.scss";
import {de} from "date-fns/locale";
import {PaymentFilterValue} from "../../../types/payment/PaymentFilterValue";
import useLabels from "../../../hooks/useLabels";
import labels from "../../../types/constants/labels";

interface Props {
  filterValue: string;
  onFilter: (filterValue: string) => void;
}

const PaymentFilter = ({ filterValue, onFilter }: Props) => {

  const { getLabel: label } = useLabels();

  const handleFilter = (filterValue: string) => {
    onFilter(filterValue);
  }


  return (
      <div className="payment-filter mb-3">
        <ul className="nav nav-pills">
          <li className="nav-item">
            <a className={`nav-link ${filterValue === 'MONTH' ? 'active' : ''}`}
              onClick={() => handleFilter(PaymentFilterValue.MONTH)}>
              {label(labels.payments.filter.MONTH)}
            </a>
          </li>
          <li className="nav-item">
            <a className={`nav-link ${filterValue === 'YEAR'? 'active' : ''}`}
               onClick={() => handleFilter(PaymentFilterValue.YEAR)}>
              {label(labels.payments.filter.YEAR)}
            </a>
          </li>
        </ul>
      </div>
  );
}

export default PaymentFilter;