import React, {useEffect} from "react";
import {Payment} from "../../types/payment/Payment";
import PaymentForm from "../../components/payment/paymentEdit/PaymentForm";
import useNewPayment from "../../hooks/payment/newPayment";
import {PaymentsState} from "../../types/state/PaymentsState";
import {useSelector} from "react-redux";

interface Props {
  showNewModal: boolean,
  defaultDate: Date,
  onInitShowNewModal: (show: boolean) => void;
  onReloadPayments: () => void;
}
const NewPaymentFragment = ({ showNewModal, defaultDate,
                             onInitShowNewModal, onReloadPayments }: Props) => {

  const { paymentTypes }: PaymentsState = useSelector((state: any) => state.payments);
  const { paymentModes }: PaymentsState = useSelector((state: any) => state.payments);
  const { paymentIntervals }: PaymentsState = useSelector((state: any) => state.payments);
  const { paymentCategories }: PaymentsState = useSelector((state: any) => state.payments);

  const {
    isLoading, hasError,
    payment, alertData,
    resetPayment,
    saveNewPayment,
    cancelNewPayment
  } = useNewPayment();

  useEffect(() => {
    resetPayment(paymentCategories, defaultDate);
  }, [defaultDate]);

  const handleSavePayment = async (payment: Payment): Promise<boolean> => {
    const wasSucess: boolean = await saveNewPayment(payment);
    if (!wasSucess) {
      return wasSucess;
    }
    await onReloadPayments();
    resetPayment(paymentCategories, defaultDate);
    return wasSucess;
  }

  const handleCancelPayment = () => {
    cancelNewPayment();
    resetPayment(paymentCategories, defaultDate);
  }

  return (
      <>
        {/* must be present so that modal works */}
        <PaymentForm payment={payment}
                     paymentTypes={paymentTypes}
                     paymentCategories={paymentCategories}
                     paymentModes={paymentModes}
                     paymentIntervals={paymentIntervals}
                     alertData={alertData}
                     mode="NEW"
                     modalId="newPaymentForm"
                     showModal={showNewModal}
                     isLoading={isLoading}
                     hasError={hasError}
                     onInitShowModal={onInitShowNewModal}
                     onSavePayment={handleSavePayment}
                     onCancel={handleCancelPayment} />
      </>
  );
}

export default NewPaymentFragment;