import Icon from "./Icon";
import "./Button.scss";

interface Props {
  type?: 'button' | 'submit' | 'reset';
  classes?: string;
  icon?: string;
  label?: string;
  disabled?: boolean;
  onClick?: () => void;
}
const Button = ({ type = 'button', classes, icon, label, disabled, onClick, ...props }: Props) => {
  return (
      <button type={type} className={`btn ${icon ? 'btn-icon' : ''} ${classes ? classes : ''}`} onClick={onClick} {...props} >
        {icon &&
            <Icon icon={icon} classes={label ? 'mr-1' : ''} />
        }
        {label &&
            <span className="btn-label">{label}</span>
        }
      </button>
  );
}

export default Button;