import React, {useEffect} from "react";
import PaymentCategoryForm from "../../components/paymentCategory/paymentCategoryEdit/PaymentCategoryForm";
import useEditPaymentCategory from "../../hooks/paymentCategory/editPaymentCategory";
import {PaymentCategory} from "../../types/paymentCategory/PaymentCategory";

interface Props {
  paymentCategoryId: number;
  showEditModal: boolean;
  onInitShowEditModal: (show: boolean) => void;
  onReloadPaymentCategories: () => void;
}

const EditPaymentCategoryFragment = ({ paymentCategoryId, showEditModal,
                              onInitShowEditModal, onReloadPaymentCategories }: Props) => {

  // const { paymentCategoryTypes }: PaymentCategoriesState = useSelector((state: any) => state.paymentCategories);

  const { isLoading, hasError,
    paymentCategory, alertData,
    loadPaymentCategory,
    saveEditPaymentCategory,
    cancelEditPaymentCategory
  } = useEditPaymentCategory();

  useEffect(() => {
    (async () => {
      if (paymentCategoryId) {
        await loadPaymentCategory(`${paymentCategoryId}`);
      }
    })();
  }, [paymentCategoryId]);

  const handleSavePaymentCategory = async (paymentCategory: PaymentCategory): Promise<boolean> => {
    const wasSucess: boolean = await saveEditPaymentCategory(paymentCategory);
    if (!wasSucess) {
      return wasSucess;
    }
    await onReloadPaymentCategories();
    return wasSucess;
  }

  const handleCancelPaymentCategory = () => {
    cancelEditPaymentCategory();
  }

  return (
      <>
        <PaymentCategoryForm paymentCategory={paymentCategory}
                     alertData={alertData}
                     mode="EDIT"
                     modalId="editPaymentCategoryForm"
                     showModal={showEditModal}
                     isLoading={isLoading}
                     hasError={hasError}
                     onInitShowModal={onInitShowEditModal}
                     onSavePaymentCategory={handleSavePaymentCategory}
                     onCancel={handleCancelPaymentCategory} />
      </>
  );

}

export default EditPaymentCategoryFragment;