import {useDispatch, useSelector} from "react-redux";
import {authActions} from "../../redux/slices/authSlice";
import {AuthState} from "../../types/state/AuthState";

const useNav = () => {

  const { language }: AuthState = useSelector((state: any) => state.auth);

  const dispatch = useDispatch();

  // const changeProject = (project: Project) => {
  //   dispatch(projectsActions.initProject(project));
  //   const { teams } = project;
  //   dispatch(projectsActions.initTeam(teams && teams.length > 0 ? teams[0] : null));
  // }
  //
  // const changeTeam = (team: Team) => {
  //   dispatch(projectsActions.initTeam(team));
  // }

  const changeLanguage = () => {
    const newLanguage = language === 'DE' ? 'EN' : 'DE';
    dispatch(authActions.initLanguage(newLanguage));
  }

  return {
    // changeProject,
    // changeTeam,
    changeLanguage,
  };

}

export default useNav;