import React, {useEffect, useState} from "react";
import {addMonths, addYears, format} from "date-fns";
import Button from "../../_general/Button";
import DATE_FORMATS from "../../../types/constants/dateFormats";
import "./PaymentPagination.scss";
import {de} from "date-fns/locale";
import {PaymentFilterValue} from "../../../types/payment/PaymentFilterValue";

interface Props {
  date: Date;
  filterValue: string;
  onInitDate: (date: Date) => void;
}

const PaymentPagination = ({ date, filterValue, onInitDate }: Props) => {

  const handlePrev = () => {
    let prevDate: Date;
    if (filterValue === PaymentFilterValue.MONTH) {
      prevDate = addMonths(date, -1);
    } else {
      prevDate = addYears(date, -1);
    }
    onInitDate(prevDate);
  }

  const handleNext = () => {
    let nextDate: Date;
    if (filterValue === PaymentFilterValue.MONTH) {
      nextDate = addMonths(date, 1);
    } else {
      nextDate = addYears(date, 1);
    }
    onInitDate(nextDate);
  }

  return (
      <div className="payment-pagination">
        <Button classes="btn-light btn-md" icon="chevron-left" onClick={handlePrev}/>
        {date &&
            <h6 className="payment-month-year">
              {filterValue === 'MONTH' ?
                  (format(date, DATE_FORMATS.MONTH_YEAR, {locale: de,})) :
                  (format(date, DATE_FORMATS.YEAR, {locale: de,}))
              }
            </h6>
        }
        <Button classes="btn-light btn-md" icon="chevron-right" onClick={handleNext}/>
      </div>
  );
}

export default PaymentPagination;