import {addMonths} from "date-fns";

export const getMonthsOfYear = (date: Date): Date[] => {
  const months: Date[] = [];
  let month: Date = new Date(date);
  month.setDate(1);
  month.setMonth(0);
  for (let i = 0; i < 12; i++) {
    months.push(month);
    month = addMonths(month, 1);
  }
  return months;
}