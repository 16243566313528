import {useState} from "react";
import useFetch from "../useFetch";
import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import {AlertData} from "../../types/components/AlertData";
import {handleError} from "../../utils/appUtils";
import useLabels from "../useLabels";
import {Payment} from "../../types/payment/Payment";
import {convertPaymentToCsv, initPayment} from "../../utils/paymentUtils";
import {PaymentFilterValue} from "../../types/payment/PaymentFilterValue";
import {saveAs} from "file-saver";

const usePaymentList = () => {
  const { getLabel: label } = useLabels();

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const { isLoading, hasError, fetchData, checkForAuthTokenError } = useFetch();
  const [alertData, setAlertData] = useState<AlertData>(null);

  const [date, setDate] = useState<Date>(null);
  const [filterValue, setFilterValue] = useState<string>(PaymentFilterValue.MONTH);


  const [pendingPayments, setPendingPayments] = useState<Payment[]>([]);
  const [placedPayments, setPlacedPayments] = useState<Payment[]>([]);

  const initDate = async (date?: Date) => {
    if (!date) {
      const now: Date = new Date();
      now.setDate(1); // first day of month
      setDate(now);
    } else {
      setDate(date);
    }
  }

  const filter = (filterValue: string) => {
    setFilterValue(filterValue);
  }

  const getPaymentYearMonth = () => {
    const paymentYear: number = date.getFullYear();
    let paymentMonth: number;
    if (filterValue === 'MONTH') {
      paymentMonth = date.getMonth() + 1
    }
    return [paymentYear, paymentMonth];
  }

  const loadPayments = async () => {
    const [paymentYear, paymentMonth] = getPaymentYearMonth();
    await loadPendingPayments(paymentYear, paymentMonth);
    await loadPlacedPayments(paymentYear, paymentMonth);
  }

  const loadPendingPayments = async (paymentYear: number, paymentMonth: number) => {
    let requestParams: string = `paymentYear=${paymentYear}`;
    if (paymentMonth) {
      requestParams += `&paymentMonth=${paymentMonth}`;
    }
    const resp = await fetchData(`/payment/list/pending?${requestParams}`, null, true);
    checkForAuthTokenError(resp);
    const errorAlert: AlertData = handleError(resp, label);
    if (errorAlert) {
      setAlertData(errorAlert);
    } else if (resp.payments) {
      const { payments } = resp;
      payments.forEach(initPayment);
      setPendingPayments(payments);
    }
  }

  const loadPlacedPayments = async (paymentYear: number, paymentMonth: number) => {
    let requestParams: string = `paymentYear=${paymentYear}`;
    if (paymentMonth) {
      requestParams += `&paymentMonth=${paymentMonth}`;
    }
    const resp = await fetchData(`/payment/list/placed?${requestParams}`, null, true);
    checkForAuthTokenError(resp);
    const errorAlert: AlertData = handleError(resp, label);
    if (errorAlert) {
      setAlertData(errorAlert);
    } else if (resp.payments) {
      const { payments } = resp;
      payments.forEach(initPayment);
      setPlacedPayments(payments);
    }
  }

  const exportPaymentsAsCsv = async () => {
    const payments = await getAllPayments();

    const paymentsCsv = convertPaymentsToCsv(payments);
    // console.log(paymentsCsv);

    savePaymentsAsCsv(paymentsCsv);
  }

  const getAllPayments = async (): Promise<Payment[]> => {
    const [paymentYear, paymentMonth] = getPaymentYearMonth();

    let requestParams: string = `paymentYear=${paymentYear}`;
    if (paymentMonth) {
      requestParams += `&paymentMonth=${paymentMonth}`;
    }
    const resp = await fetchData(`/payment/list/all?${requestParams}`, null, true);
    checkForAuthTokenError(resp);
    const errorAlert: AlertData = handleError(resp, label);
    if (errorAlert) {
      setAlertData(errorAlert);
    } else if (resp.payments) {
      const { payments } = resp;
      return payments;
    }
  }

  const convertPaymentsToCsv = (payments: Payment[]): string => {
    if (!payments || payments.length === 0) {
      return;
    }

    const headers = 'ID;Datum;Bezeichnung;Betrag;Kategorie;Typ;Modus;Status\n';
    const rows = payments.map(payment => convertPaymentToCsv(payment, label)).join('\n');

    return headers + rows;
  }

  const savePaymentsAsCsv = (paymentsCsv: string) => {
    if (!paymentsCsv) {
      console.log('Keine Zahlungen verfügbar zum Exportieren.');
      return;
    }

    const [paymentYear, paymentMonth] = getPaymentYearMonth();

    const blob = new Blob([paymentsCsv], { type: 'text/csv;charset=utf-8;' });

    let fileName;
    if (paymentMonth) {
      fileName = `payments_${paymentYear}_${paymentMonth < 10 ? '0' : ''}${paymentMonth}.csv`;
    } else {
      fileName = `payments_${paymentYear}.csv`;
    }
    saveAs(blob, fileName);
  }

  return {
    isLoading,
    hasError,
    alertData,
    date,
    filterValue,
    pendingPayments,
    placedPayments,
    initDate,
    filter,
    loadPayments,
    exportPaymentsAsCsv,
  };

}

export default usePaymentList;