import "./Table.scss";

interface Props {
  children: any;
  classes?: string;
}
const Table = ({ children, classes }: Props) => {
  return (
      <table className={`table ${classes ? classes : ''}`}>
        {children}
      </table>
  );
}

export default Table;