import {useState} from "react";
import useFetch from "../useFetch";
import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import {AlertData} from "../../types/components/AlertData";
import {handleError} from "../../utils/appUtils";
import useLabels from "../useLabels";
import {PaymentCategory} from "../../types/paymentCategory/PaymentCategory";
import {sortPaymentCategories} from "../../utils/paymentCategoryUtils";
import {paymentsActions} from "../../redux/slices/paymentsSlice";

const usePaymentCategoryList = () => {
  const { getLabel: label } = useLabels();

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const { isLoading, hasError, fetchData, checkForAuthTokenError } = useFetch();
  const [alertData, setAlertData] = useState<AlertData>(null);

  const [paymentCategories, setPaymentCategories] = useState<PaymentCategory[]>([]);

  const loadPaymentCategories = async (sort?: boolean) => {
    const resp = await fetchData(`/payment-category/list`, null, true);
    checkForAuthTokenError(resp);
    const errorAlert: AlertData = handleError(resp, label);
    if (errorAlert) {
      setAlertData(errorAlert);
    } else if (resp.paymentCategories) {
      const { paymentCategories } = resp;
      setPaymentCategories(paymentCategories);
      dispatch(paymentsActions.initPaymentCategories(sortPaymentCategories(paymentCategories)));
    }
  }


  return {
    isLoading,
    hasError,
    alertData,
    paymentCategories,
    loadPaymentCategories,
  };

}

export default usePaymentCategoryList;