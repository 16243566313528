import {AlertData} from "../types/components/AlertData";
import {UserData} from "../types/user/UserData";
import FEATURES from "../types/constants/features";
import {ERROR_CODE_LABELS, ERROR_CODES} from "../types/constants/errorCodes";

export const handleError = (resp: any, label: any): AlertData => {
  if (!resp) {
    return createErrorAlert(label(ERROR_CODE_LABELS.FETCH_ERROR));
  }
  if (!resp.errorCode) {
    return;
  }

  let message = label(ERROR_CODE_LABELS[resp.errorCode]);

  if (!message) {
    if (resp.message) {
      message = resp.message;
    } else {
      message = label(ERROR_CODE_LABELS.UNKNOWN_ERROR);
    }
  }

  return createErrorAlert(message);
}

export const createErrorAlert = (message: string): AlertData => {
  return {
    type: 'danger',
    message: message,
  };
}

export const createWarningAlert = (message: string): AlertData => {
  return {
    type: 'warning',
    message: message,
  };
}

export const createSuccessAlert = (message: string): AlertData => {
  return {
    type: 'success',
    message: message,
  };
}

export const hasFeature = (feature: string, userData: UserData) => {
  if (!feature || !userData) {
    return;
  }
  const { features } = userData;
  return features && (features.includes(FEATURES.ALL) || features.includes(feature));
}