import React, {useEffect} from "react";
import {PaymentCategory} from "../../types/paymentCategory/PaymentCategory";
import PaymentCategoryForm from "../../components/paymentCategory/paymentCategoryEdit/PaymentCategoryForm";
import useNewPaymentCategory from "../../hooks/paymentCategory/newPaymentCategory";

interface Props {
  showNewModal: boolean,
  onInitShowNewModal: (show: boolean) => void;
  onReloadPaymentCategories: () => void;
}
const NewPaymentCategoryFragment = ({ showNewModal,
                             onInitShowNewModal, onReloadPaymentCategories }: Props) => {

  const { isLoading, hasError,
    paymentCategory, alertData,
    resetPaymentCategory,
    saveNewPaymentCategory,
    cancelNewPaymentCategory
  } = useNewPaymentCategory();

  useEffect(() => {
      resetPaymentCategory();
  }, []);

  const handleSavePaymentCategory = async (paymentCategory: PaymentCategory): Promise<boolean> => {
    const wasSucess: boolean = await saveNewPaymentCategory(paymentCategory);
    if (!wasSucess) {
      return wasSucess;
    }
    await onReloadPaymentCategories();
    resetPaymentCategory();
    return wasSucess;
  }

  const handleCancelPaymentCategory = () => {
    cancelNewPaymentCategory();
    resetPaymentCategory();
  }

  return (
      <>
        {/* must be present so that modal works */}
        <PaymentCategoryForm paymentCategory={paymentCategory}
                     alertData={alertData}
                     mode="NEW"
                     modalId="newPaymentCategoryForm"
                     showModal={showNewModal}
                     isLoading={isLoading}
                     hasError={hasError}
                     onInitShowModal={onInitShowNewModal}
                     onSavePaymentCategory={handleSavePaymentCategory}
                     onCancel={handleCancelPaymentCategory} />
      </>
  );
}

export default NewPaymentCategoryFragment;