import React from "react";
import useDeletePaymentCategory from "../../hooks/paymentCategory/deletePaymentCategory";
import Modal from "../../components/_general/Modal";
import Button from "../../components/_general/Button";
import Alert from "../../components/_general/Alert";
import useLabels from "../../hooks/useLabels";
import labels from "../../types/constants/labels";
import {PaymentCategory} from "../../types/paymentCategory/PaymentCategory";

interface Props {
  paymentCategory: PaymentCategory;
  showDeleteModal: boolean;
  onInitShowDeleteModal: (show: boolean) => void;
  onReloadPaymentCategories: () => void;
}

const DeletePaymentCategoryFragment = ({ paymentCategory, showDeleteModal,
                                onInitShowDeleteModal, onReloadPaymentCategories }: Props) => {

  const { getLabel: label } = useLabels();

  const {
    isLoading,
    hasError,
    alertData,
    deletePaymentCategory
  } = useDeletePaymentCategory();

  const handleDeletePaymentCategory = async () => {
    await deletePaymentCategory(paymentCategory);
    await onReloadPaymentCategories();
    onInitShowDeleteModal(false);
  }

  const handleCancel = () => {
    onInitShowDeleteModal(false);
  }

  return (
      <>
        <Modal id="deletePaymentCategoryForm"
               showModal={showDeleteModal}
               title={label(labels.paymentCategories.actions.DELETE_CATEGORY)}
               onCancel={handleCancel}>
          {alertData &&
              <Alert alertData={alertData} />
          }
          {paymentCategory &&
            <>
              <div className="modal-body">
                <p>{label(labels.paymentCategories.actions.REALLY_DELETE_CATEGORY, `${paymentCategory.categoryName}`)}</p>
              </div>
              <div className="modal-footer">
                <Button classes="btn-light" icon="x" label={label(labels.actions.CANCEL)}
                    onClick={() => { onInitShowDeleteModal(false) } } />
                <Button classes="btn-primary" icon="check" label={label(labels.actions.DELETE)} onClick={handleDeletePaymentCategory} />
              </div>
            </>
          }
        </Modal>
      </>
  );

}

export default DeletePaymentCategoryFragment;