import useFetch from "../useFetch";
import {useDispatch} from "react-redux";
import {UserData} from "../../types/user/UserData";
import {PaymentType} from "../../types/paymentType/PaymentType";
import {paymentsActions} from "../../redux/slices/paymentsSlice";
import {PaymentMode} from "../../types/paymentMode/PaymentMode";
import {PaymentInterval} from "../../types/paymentInterval/PaymentInterval";
import {PaymentCategory} from "../../types/paymentCategory/PaymentCategory";
import {AlertData} from "../../types/components/AlertData";
import {handleError} from "../../utils/appUtils";
import {sortPaymentCategories} from "../../utils/paymentCategoryUtils";
const useDataStore = () => {

  const {
    isLoading,
    hasError,
    fetchData
  } = useFetch();
  const dispatch = useDispatch();

  const loadData = async (userData: UserData) => {
    const paymentTypes: PaymentType[] = await loadPaymentTypes(userData);
    console.log(paymentTypes);
    const paymentModes: PaymentMode[] = await loadPaymentModes(userData);
    console.log(paymentModes);
    const paymentIntervals: PaymentInterval[] = await loadPaymentIntervals(userData);
    console.log(paymentIntervals);
    const paymentCategories: PaymentCategory[] = await loadPaymentCategories(userData);
    console.log(paymentCategories);

    if (paymentTypes) {
      dispatch(paymentsActions.initPaymentTypes(paymentTypes));
    }
    if (paymentModes) {
      dispatch(paymentsActions.initPaymentModes(paymentModes));
    }
    if (paymentIntervals) {
      dispatch(paymentsActions.initPaymentIntervals(paymentIntervals));
    }
    if (paymentCategories){
      dispatch(paymentsActions.initPaymentCategories(paymentCategories));
    }
  };

  const loadPaymentTypes = async (userData: UserData): Promise<PaymentType[]> => {
    const resp = await fetchData(`/payment-type/list`, null, true, userData);
    if (resp && !resp.errorCode && resp.paymentTypes) {
      return resp.paymentTypes;
    }
  }

  const loadPaymentModes = async (userData: UserData): Promise<PaymentMode[]> => {
    const resp = await fetchData(`/payment-mode/list`, null, true, userData);
    if (resp && !resp.errorCode && resp.paymentModes) {
      return resp.paymentModes;
    }
  }

  const loadPaymentIntervals = async (userData: UserData): Promise<PaymentInterval[]> => {
    const resp = await fetchData(`/payment-interval/list`, null, true, userData);
    if (resp && !resp.errorCode && resp.paymentIntervals) {
      return resp.paymentIntervals;
    }
  }

  const loadPaymentCategories = async (userData: UserData): Promise<PaymentCategory[]> => {
      const resp = await fetchData(`/payment-category/list`, null, true, userData);
      if (resp && !resp.errorCode && resp.paymentCategories) {
        const { paymentCategories } = resp;
        return sortPaymentCategories(paymentCategories);
      }
  }

  return {
    loadData,
  };

}

export default useDataStore;