import {useState} from "react";
import useFetch from "../useFetch";
import {createPostRequest} from "../../utils/apiUtils";
import {AlertData} from "../../types/components/AlertData";
import {createSuccessAlert, handleError} from "../../utils/appUtils";
import {Payment} from "../../types/payment/Payment";
import {useDispatch} from "react-redux";
import {pageActions} from "../../redux/slices/pageSlice";
import useLabels from "../useLabels";
import labels from "../../types/constants/labels";

const useDeletePayment = () => {

  const { getLabel: label } = useLabels();

  const dispatch = useDispatch();

  const { isLoading, hasError, fetchData } = useFetch();

  const [alertData, setAlertData] = useState<AlertData>(null);

  const deletePayment = async  (payment: Payment) => {
    const reqData = createPostRequest(null);
    const respData = await fetchData(`/payment/delete/${payment.id}`, reqData, true);
    if (!respData) {
      return;
    }

    const errorAlert: AlertData = handleError(respData, label);
    if (errorAlert) {
      setAlertData(errorAlert);
      return;
    }

    const successAlert: AlertData = createSuccessAlert(label(labels.payments.alerts.DELETED_SUCCESSFULLY,
        `${payment.paymentName}`));
    dispatch(pageActions.initAlertData(successAlert));
  }

  return {
    isLoading,
    hasError,
    alertData,
    deletePayment,
  };

}

export default useDeletePayment;