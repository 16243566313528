import {createPostRequest} from "../../utils/apiUtils";
import {AlertData} from "../../types/components/AlertData";
import {createWarningAlert, handleError} from "../../utils/appUtils";
import {UserData} from "../../types/user/UserData";
import {LoginData} from "../../types/user/LoginData";
import {useState} from "react";
import useFetch from "../useFetch";
import {useNavigate, useSearchParams} from "react-router-dom";
import useDataStore from "../dataStore/dataStore";
import {useDispatch, useSelector} from "react-redux";
import {authActions} from "../../redux/slices/authSlice";
import {AuthState} from "../../types/state/AuthState";
import useLabels from "../useLabels";
import labels from "../../types/constants/labels";
import {pageActions} from "../../redux/slices/pageSlice";
import {paymentsActions} from "../../redux/slices/paymentsSlice";

const useLogin = () => {

  const { getLabel: label } = useLabels();
  const { language }: AuthState = useSelector((state: any) => state.auth);

  const dataStore = useDataStore();

  const dispatch = useDispatch();

  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const { isLoading, hasError, fetchData } = useFetch();

  const [alertData, setAlertData] = useState<AlertData>(null);

  const initLogin = () => {
    const eventType = searchParams.get('eventType');
    if (eventType && eventType === 'logged_out') {
      const warningAlert: AlertData = createWarningAlert(label(labels.login.errors.LOGGED_OUT));
      setAlertData(warningAlert);
    }
  }

  const login = async (loginData: LoginData) => {
    const eventType: string = searchParams.get('eventType');
    if (eventType) {
      searchParams.delete('eventType');
    }

    const reqData = createPostRequest(loginData);
    const respData = await fetchData('/user/login', reqData, false);
    if (!respData) {
      return;
    }

    const errorAlert: AlertData = handleError(respData, label);
    if (errorAlert) {
      setAlertData(errorAlert);
      return;
    }

    // console.log(respData);
    const userData: UserData = {
      ...respData.user,
    };

    dispatch(authActions.login(userData));
    await dataStore.loadData(userData);

    navigate('/payments');
  }

  const logout = () => {
    dispatch(authActions.logout());
    dispatch(pageActions.clear());
    dispatch(paymentsActions.clear());

    navigate('/login');
  }

  return {
    isLoading,
    hasError,
    alertData,
    initLogin,
    login,
    logout,
  };

}

export default useLogin;