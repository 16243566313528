import {useState} from "react";
import useFetch from "../useFetch";
import {createPostRequest} from "../../utils/apiUtils";
import {AlertData} from "../../types/components/AlertData";
import {createSuccessAlert, handleError} from "../../utils/appUtils";
import {PaymentCategory} from "../../types/paymentCategory/PaymentCategory";
import {useDispatch} from "react-redux";
import {pageActions} from "../../redux/slices/pageSlice";
import useLabels from "../useLabels";
import labels from "../../types/constants/labels";

const useDeletePaymentCategory = () => {

  const { getLabel: label } = useLabels();

  const dispatch = useDispatch();

  const { isLoading, hasError, fetchData } = useFetch();

  const [alertData, setAlertData] = useState<AlertData>(null);

  const deletePaymentCategory = async  (paymentCategory: PaymentCategory) => {
    const reqData = createPostRequest(null);
    const respData = await fetchData(`/payment-category/delete/${paymentCategory.id}`, reqData, true);
    if (!respData) {
      return;
    }

    const errorAlert: AlertData = handleError(respData, label);
    if (errorAlert) {
      setAlertData(errorAlert);
      return;
    }

    const successAlert: AlertData = createSuccessAlert(label(labels.paymentCategories.alerts.DELETED_SUCCESSFULLY,
        `${paymentCategory.categoryName}`));
    dispatch(pageActions.initAlertData(successAlert));
  }

  return {
    isLoading,
    hasError,
    alertData,
    deletePaymentCategory,
  };

}

export default useDeletePaymentCategory;