import {Payment} from "../../../types/payment/Payment";
import "./PaymentDetails.scss";
import useLabels from "../../../hooks/useLabels";
import labels from "../../../types/constants/labels";
import {format} from "date-fns";
import DATE_FORMATS from "../../../types/constants/dateFormats";
import {formatCurrency} from "../../../utils/formatUtils";
import Tag from "../../_general/Tag";
import {PaymentStatus} from "../../../types/payment/PaymentStatus";
import {getPaymentStatusLabel} from "../../../utils/paymentUtils";
import Button from "../../_general/Button";

interface Props {
  payment: Payment;
  paymentStatus: PaymentStatus;
  paymentItemTypeClass: string;
  onInitShowEditModal: (show: boolean) => void;
  onInitShowDeleteModal: (show: boolean) => void;
  onInitEditPayment: (payment: Payment) => void;
  onInitDeletePayment: (payment: Payment) => void;
  onUpdatePaymentStatus: (payment: Payment, paymentStatus: PaymentStatus) => void;
}
const PaymentDetails = ({ payment, paymentStatus, paymentItemTypeClass,
                          onInitShowEditModal, onInitShowDeleteModal, onInitEditPayment, onInitDeletePayment, onUpdatePaymentStatus }: Props) => {

  const { getLabel: label } = useLabels();

  const handleEditClick = () => {
    onInitShowEditModal(true);
    onInitEditPayment(payment);
  }

  const handleDeleteClick = () => {
    onInitShowDeleteModal(true);
    onInitDeletePayment(payment);
  }

  const handleUpdatePaymentStatus = (paymentStatus: PaymentStatus) => {
    onUpdatePaymentStatus(payment, paymentStatus);
  }

  return (
      <>
        <div className={`payment-details ${paymentItemTypeClass}`}>
          <div className="payment-details-actions">
              <Button classes="btn-link btn-sm" icon="pencil"
                      label={label(labels.actions.EDIT)}
                      onClick={handleEditClick} />

              {paymentStatus === PaymentStatus.PENDING &&
                <Button classes="btn-link btn-sm" icon="check"
                        label={label(labels.payments.status.PLACED)}
                        onClick={() => handleUpdatePaymentStatus(PaymentStatus.PLACED) } />
              }
            {paymentStatus === PaymentStatus.PLACED &&
                <Button classes="btn-link btn-sm" icon="arrow-long-left"
                        label={label(labels.payments.status.PENDING)}
                        onClick={() => handleUpdatePaymentStatus(PaymentStatus.PENDING) } />
            }

              <Button classes="btn-link btn-sm" icon="trash"
                    label={label(labels.actions.DELETE)}
                    onClick={handleDeleteClick} />
          </div>
          <div className="grid grid--2-cols">
            <div className="payment-details-grid">
              <div className="text-right">
                <label className="payment-details-label">{label(labels.payments.fields.ID)}</label>
              </div>
              <div>
                #{payment.id}
              </div>

              <div className="text-right">
                <label className="payment-details-label">{label(labels.payments.fields.PAYMENT_DATE)}</label>
              </div>
              <div>
                {format(payment.paymentDateAsDate, DATE_FORMATS.DATE_DE)}
              </div>

              <div className="text-right">
                <label className="payment-details-label">{label(labels.payments.fields.PAYMENT_NAME)}</label>
              </div>
              <div>
                {payment.paymentName}
              </div>

              <div className="text-right">
                <label className="payment-details-label">{label(labels.payments.fields.AMOUNT)}</label>
              </div>
              <div className="amount">
                {formatCurrency(payment.displayAmount)}
              </div>

              <div className="text-right">
                <label className="payment-details-label">{label(labels.payments.fields.PAYMENT_CATEGORY)}</label>
              </div>
              <div>
                <Tag label={payment.paymentCategory.categoryName}
                     color={`#${payment.paymentCategory.color}`}/>
              </div>

              <div className="text-right">
                <label className="payment-details-label">{label(labels.payments.fields.PAYMENT_TYPE)}</label>
              </div>
              <div>
                {payment.paymentType.typeName}
              </div>
            </div>

            <div className="payment-details-grid">
              <div className="text-right">
                <label className="payment-details-label">{label(labels.payments.fields.PAYMENT_MODE)}</label>
              </div>
              <div>
                {payment.paymentMode.modeName}
              </div>

              <div className="text-right">
                <label className="payment-details-label">{label(labels.payments.fields.STATUS)}</label>
              </div>
              <div>
                {label(getPaymentStatusLabel(paymentStatus))}
              </div>

              <div className="text-right">
                <label className="payment-details-label">{label(labels.payments.fields.DEDUCTIBLE)}</label>
              </div>
              <div>
                <input type="checkbox" className="form-check-input"
                       checked={payment.deductible} readOnly={true}/>
              </div>

              <div className="text-right">
                <label className="payment-details-label">{label(labels.payments.fields.CREATE_DATE)}</label>
              </div>
              <div>
                {payment.createDateAsDate &&
                    format(payment.createDateAsDate, DATE_FORMATS.DATE_DE)
                }
              </div>

              <div className="text-right">
                <label className="payment-details-label">{label(labels.payments.fields.CREATE_USER)}</label>
              </div>
              <div>
                {payment.createUser &&
                    `${payment.createUser.firstName} ${payment.createUser.lastName}`
                }
              </div>
            </div>
          </div>
        </div>
      </>
  );
}

export default PaymentDetails;