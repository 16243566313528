import {createBrowserRouter} from "react-router-dom";
import ErrorPage from "../pages/errors/ErrorPage";
import RootLayout from "../pages/layout/RootLayout";
import LoginPage from "../pages/login/LoginPage";
import AuthLayout from "../pages/layout/AuthLayout";
import AccountPage from "../pages/user/AccountPage";
import React from "react";
import PaymentCategoryListPage from "../pages/paymentCategory/PaymentCategoryListPage";
import PaymentListPage from "../pages/payment/PaymentListPage";
import StatsPage from "../pages/stats/StatsPage";

const appRouter = createBrowserRouter([
  {
    path: '/',
    element: <RootLayout/>,
    errorElement: <ErrorPage/>,

    children: [

      { path: '/login',
        element: <LoginPage />, },

      { path: '/',
        element: <AuthLayout />,

        children: [
          { path: '/account',
            element: <AccountPage /> },

          { path: '/payments',
            element: <PaymentListPage /> },

          { path: '/stats',
            element: <StatsPage /> },

          { path: '/payment-categories',
            element: <PaymentCategoryListPage /> },
        ]
      },

    ],
  },
]);

export default appRouter;