import "./PaymentItem.scss";
import {Payment} from "../../../types/payment/Payment";
import {PaymentStatus} from "../../../types/payment/PaymentStatus";
import {useCallback, useState} from "react";
import {PAYMENT_TYPE_EXPENSE, PAYMENT_TYPE_INCOME, PaymentType} from "../../../types/paymentType/PaymentType";
import {format} from "date-fns";
import DATE_FORMATS from "../../../types/constants/dateFormats";
import {formatCurrency} from "../../../utils/formatUtils";
import Tag from "../../_general/Tag";
import Icon from "../../_general/Icon";
import {PAYMENT_MODE_RECURRING} from "../../../types/paymentMode/PaymentMode";
import {Link} from "react-router-dom";
import Button from "../../_general/Button";
import PaymentDetails from "./PaymentDetails";

interface Props {
  payment: Payment;
  paymentStatus: PaymentStatus;
  onInitShowEditModal: (show: boolean) => void;
  onInitShowDeleteModal: (show: boolean) => void;
  onInitEditPayment: (payment: Payment) => void;
  onInitDeletePayment: (payment: Payment) => void;
  onUpdatePaymentStatus: (payment: Payment, paymentStatus: PaymentStatus) => void;
}
const PaymentItem = ({ payment, paymentStatus,
                       onInitShowEditModal, onInitShowDeleteModal, onInitEditPayment, onInitDeletePayment, onUpdatePaymentStatus}: Props) => {

  const [showDetails, setShowDetails] = useState<boolean>(false);

  // const getPaymentItemClass = useCallback((paymentTypeId: PaymentType): string => {
  //   switch (paymentTypeId) {
  //     case PaymentStatus.OPEN:
  //       return 'open';
  //     case PaymentStatus.IN_PROGRESS:
  //       return 'in-progress';
  //     case PaymentStatus.DONE:
  //       return 'done';
  //     default:
  //       return '';
  //   }
  // }, []);

  const getPaymentItemStatusClass = useCallback((paymentStatus: PaymentStatus): string => {
    switch (paymentStatus) {
      case (PaymentStatus.PENDING):
        return 'pending';
      case (PaymentStatus.PLACED):
        return 'placed';
      default:
        return '';
    }

  }, []);

  const getPaymentItemTypeClass = useCallback((paymentType: PaymentType): string => {
    switch (paymentType.id) {
      case PAYMENT_TYPE_INCOME:
        return 'income';
      case PAYMENT_TYPE_EXPENSE:
        return 'expense';
      default:
        return '';
    }

  }, []);


  const handleToggleDetails = () => {
    setShowDetails((prevShowDetails) => !prevShowDetails);
  }

  const handlePaymentDetailsClick = () => {
    onInitShowEditModal(true);
    onInitEditPayment(payment);
  }

  return (
      <>
        <div className={`payment-list-item ${getPaymentItemStatusClass(paymentStatus)} 
                                            ${getPaymentItemTypeClass(payment.paymentType)}`}>
          <div>
            <Button classes="btn-light btn-sm btn-round"
                    icon={`${!showDetails ? 'chevron-down' : 'chevron-up'}`}
                    onClick={handleToggleDetails}/>
          </div>
          <div>
            <Link to="/payments" className="link-primary" onClick={handlePaymentDetailsClick}>#{payment.id}</Link>
          </div>
          <div>
            {format(payment.paymentDateAsDate, DATE_FORMATS.DATE_DE)}
          </div>
          <div>
            {payment.paymentName}
          </div>
          <div>
            <Tag label={payment.paymentCategory.categoryName} 
                 color={`#${payment.paymentCategory.color}`} />
          </div>
          <div className="d-flex gap-3">
            {payment.paymentMode.id === PAYMENT_MODE_RECURRING &&
              <Icon icon="arrow-path" />
            }
            {payment.deductible &&
              <Icon icon="banknotes" />
            }
          </div>
          <div className="amount text-right">
            {formatCurrency(payment.displayAmount)}
          </div>
        </div>

        {showDetails &&
          <PaymentDetails payment={payment}
                          paymentStatus={paymentStatus}
                          paymentItemTypeClass={getPaymentItemTypeClass(payment.paymentType)}
                          onInitShowEditModal={onInitShowEditModal}
                          onInitShowDeleteModal={onInitShowDeleteModal}
                          onInitEditPayment={onInitEditPayment}
                          onInitDeletePayment={onInitDeletePayment}
                          onUpdatePaymentStatus={onUpdatePaymentStatus}
          />
        }
      </>
  );
}

export default PaymentItem;