import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {BreacrumbData} from "../../types/components/BreacrumbData";
import labels from "../../types/constants/labels";
import useLabels from "../../hooks/useLabels";
import Breadcrumb from "../../components/_general/Breadcrumb";
import {Payment} from "../../types/payment/Payment";
import Alert from "../../components/_general/Alert";
import Button from "../../components/_general/Button";
import NewPaymentFragment from "../../pageFragments/payment/NewPaymentFragment";
import EditPaymentFragment from "../../pageFragments/payment/EditPaymentFragment";
import DeletePaymentFragment from "../../pageFragments/payment/DeletePaymentFragment";
import {PageState} from "../../types/state/PageState";
import {pageActions} from "../../redux/slices/pageSlice";
import PaymentList from "../../components/payment/paymentList/PaymentList";
import usePaymentList from "../../hooks/payment/paymentList";
import {PaymentStatus} from "../../types/payment/PaymentStatus";
import PaymentPagination from "../../components/payment/paymentList/PaymentPagination";
import PaymentSummary from "../../components/payment/paymentList/PaymentSummary";
import {PAYMENT_TYPE_INCOME} from "../../types/paymentType/PaymentType";
import {
  calcTotalExpenses,
  calcTotalIncome,
  getPendingPaymentsByMonth,
  getPlacedPaymentsByMonth
} from "../../utils/paymentUtils";
import "./PaymentListPage.scss";
import useUpdatePaymentStatus from "../../hooks/payment/updatePaymentStatus";
import PaymentFilter from "../../components/payment/paymentList/PaymentFilter";
import {PaymentFilterValue} from "../../types/payment/PaymentFilterValue";
import {addMonths, format} from "date-fns";
import {getMonthsOfYear} from "../../utils/dateUtils";
import DATE_FORMATS from "../../types/constants/dateFormats";
import {de} from "date-fns/locale";

const PaymentListPage = () => {
  const { getLabel: label } = useLabels();
  const dispatch = useDispatch();

  const { alertData: globalAlertData }: PageState = useSelector((state: any) => state.page);

  // const { projectTypes }: ProjectsState = useSelector((state: any) => state.projects);

  const [selectedPaymentId, setSelectedPaymentId] = useState<number>(null);
  const [selectedPayment, setSelectedPayment] = useState<Payment>(null);

  const [showNewModal, setShowNewModal] = useState<boolean>(false);
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

  const {
    isLoading,
    hasError,
    date,
    filterValue,
    pendingPayments,
    placedPayments,
    alertData,
    initDate,
    filter,
    loadPayments,
    exportPaymentsAsCsv,
  } = usePaymentList();

  const {
    updatePaymentStatus,
  } = useUpdatePaymentStatus();

  const breadcrumbPages: BreacrumbData[] = [{ item: label(labels.payments.titles.PAYMENTS), to: '/payments'},];

  let pendingPaymentsByMonth: Map<number, Payment[]>;
  let placedPaymentsByMonth: Map<number, Payment[]>;
  let months: Date[] = [];

  const income = calcTotalIncome(pendingPayments, placedPayments);
  const expenses = calcTotalExpenses(pendingPayments, placedPayments);
  const balance = income + expenses;

  const initPaymentsByMonth = () => {
    if (filterValue === PaymentFilterValue.YEAR) {
      pendingPaymentsByMonth = getPendingPaymentsByMonth(pendingPayments);
      placedPaymentsByMonth = getPlacedPaymentsByMonth(placedPayments);
      months = getMonthsOfYear(date);
    } else {
      pendingPaymentsByMonth = null;
      placedPaymentsByMonth = null;
      months = [];
    }
  }

  initPaymentsByMonth();

  useEffect(() => {
    initDate();
  }, []);

  useEffect(() => {
    (async () => {
      if (date && filterValue) {
        await loadPayments();
      }
    })();
  }, [date, filterValue]);

  useEffect(() => {
    let timer = setTimeout(() => {
      dispatch(pageActions.clear());
    }, 5 * 1000);
    return () => {
      clearTimeout(timer);
    };
  }, [globalAlertData]);


  const initShowNewModal = (show: boolean) => {
    setShowNewModal(show);
  }

  const initShowEditModal = (show: boolean) => {
    setShowEditModal(show);
  }

  const initShowDeleteModal = (show: boolean) => {
    setShowDeleteModal(show);
  }

  const initEditPayment = (payment: Payment) => {
    setSelectedPaymentId(payment ? payment.id : null);
  }

  const initDeletePayment = (payment: Payment) => {
    setSelectedPayment(payment);
  }

  const handleNewClick = () => {
    initShowNewModal(true);
  }

  const handleExportClick = async () => {
    await exportPaymentsAsCsv();
  }

  const handleUpdatePaymentStatus = async (payment: Payment, paymentStatus: PaymentStatus) => {
    if (payment && paymentStatus) {
      await updatePaymentStatus(payment, paymentStatus);
      await loadPayments();
    }
  };

  return (
      <>
        {(globalAlertData ?? alertData) &&
            <Alert alertData={globalAlertData ?? alertData}/>
        }

        <Breadcrumb pages={breadcrumbPages}/>

        <div className="main-header mb-3">
          <h4>{label(labels.payments.titles.PAYMENTS)}</h4>
          <div>
            <Button classes="btn-light mr-2" icon="arrow-down-tray" label={label(labels.actions.EXPORT)}
                    onClick={handleExportClick}/>
            <Button classes="btn-primary" icon="plus" label={label(labels.actions.ADD)}
                    onClick={handleNewClick}/>
          </div>
        </div>

        <div className="payment-list-header mb-4">
          <div>
            <PaymentFilter filterValue={filterValue}
                           onFilter={filter} />
            <PaymentPagination date={date}
                               filterValue={filterValue}
                               onInitDate={initDate} />
          </div>

          <PaymentSummary income={income}
                          expenses={expenses}
                          balance={balance} />
        </div>

        {filterValue === PaymentFilterValue.MONTH ?
            <>
              <PaymentList payments={pendingPayments}
                           paymentStatus={PaymentStatus.PENDING}
                           isLoading={isLoading}
                           onInitShowEditModal={initShowEditModal}
                           onInitShowDeleteModal={initShowDeleteModal}
                           onInitEditPayment={initEditPayment}
                           onInitDeletePayment={initDeletePayment}
                           onUpdatePaymentStatus={handleUpdatePaymentStatus}
              />

              <PaymentList payments={placedPayments}
                           paymentStatus={PaymentStatus.PLACED}
                           isLoading={isLoading}
                           onInitShowEditModal={initShowEditModal}
                           onInitShowDeleteModal={initShowDeleteModal}
                           onInitEditPayment={initEditPayment}
                           onInitDeletePayment={initDeletePayment}
                           onUpdatePaymentStatus={handleUpdatePaymentStatus}
              />
            </>
          :
            <>
              {months.map((month: Date, index: number) => (
                  (pendingPaymentsByMonth.get(month.getMonth() + 1) ||
                  placedPaymentsByMonth.get(month.getMonth() + 1)) &&
                    <div className="mb-4" key={index}>
                      <h6 className="mb-3">{format(month, DATE_FORMATS.MONTH_YEAR, {locale: de,})}</h6>

                      <PaymentList payments={pendingPaymentsByMonth.get(month.getMonth() + 1)}
                                   paymentStatus={PaymentStatus.PENDING}
                                   isLoading={isLoading}
                                   onInitShowEditModal={initShowEditModal}
                                   onInitShowDeleteModal={initShowDeleteModal}
                                   onInitEditPayment={initEditPayment}
                                   onInitDeletePayment={initDeletePayment}
                                   onUpdatePaymentStatus={handleUpdatePaymentStatus}
                      />

                      <PaymentList payments={placedPaymentsByMonth.get(month.getMonth() + 1)}
                                   paymentStatus={PaymentStatus.PLACED}
                                   isLoading={isLoading}
                                   onInitShowEditModal={initShowEditModal}
                                   onInitShowDeleteModal={initShowDeleteModal}
                                   onInitEditPayment={initEditPayment}
                                   onInitDeletePayment={initDeletePayment}
                                   onUpdatePaymentStatus={handleUpdatePaymentStatus}
                      />
                    </div>
              ))}
            </>
        }

        <NewPaymentFragment showNewModal={showNewModal}
                            defaultDate={date}
                            onInitShowNewModal={initShowNewModal}
                            onReloadPayments={loadPayments}/>

        <EditPaymentFragment paymentId={selectedPaymentId}
                             showEditModal={showEditModal}
                             onInitShowEditModal={initShowEditModal}
                             onReloadPayments={loadPayments}/>

        <DeletePaymentFragment payment={selectedPayment}
                               showDeleteModal={showDeleteModal}
                               onInitShowDeleteModal={initShowDeleteModal}
                               onReloadPayments={loadPayments}/>

      </>
  );

}

export default PaymentListPage;