import {Status} from "../types/_general/Status";
import labels from "../types/constants/labels";
import {SelectItem} from "../types/_general/SelectItem";
import {PAYMENT_STATES, PaymentStatus} from "../types/payment/PaymentStatus";
import {Payment} from "../types/payment/Payment";
import {PAYMENT_TYPE_EXPENSE, PAYMENT_TYPE_INCOME} from "../types/paymentType/PaymentType";
import {format, parse} from "date-fns";
import DATE_FORMATS from "../types/constants/dateFormats";
import {PAYMENT_MODE_ONCE, PAYMENT_MODE_RECURRING} from "../types/paymentMode/PaymentMode";
import {PaymentCategory} from "../types/paymentCategory/PaymentCategory";

export const findPaymentStatus = (status: Status): PaymentStatus => {
  return PAYMENT_STATES.find((paymentStatus: PaymentStatus) => {
    return paymentStatus === status.id;
  });
}

export const getPaymentStatusName = (paymentStatus: PaymentStatus): string => {
  switch (paymentStatus) {
    case PaymentStatus.PLACED:
      return 'PLACED';
    case PaymentStatus.PENDING:
      return 'PENDING';
    default:
      return '';
  }
}

export const getPaymentStatusLabel = (paymentStatus: PaymentStatus): string => {
  switch (paymentStatus) {
    case PaymentStatus.PLACED:
      return labels.payments.status.PLACED;
    case PaymentStatus.PENDING:
      return labels.payments.status.PENDING;
    default:
      return '';
  }
}

export const getPaymentStatusItems = (): SelectItem[] => {
  const paymentStatusItems: SelectItem[] = PAYMENT_STATES.map((paymentStatus: PaymentStatus) => {
    const paymentStatusItem: SelectItem = {
      value: paymentStatus,
      label: getPaymentStatusLabel(paymentStatus),
    }
    return paymentStatusItem;
  });
  return paymentStatusItems;
}

export const calcTotalIncome = (pendingPayments: Payment[], placedPayments: Payment[]) => {
  let income = 0.0;
  if (pendingPayments && pendingPayments.length > 0) {
    income += pendingPayments.filter((payment: Payment) => payment.paymentType.id === PAYMENT_TYPE_INCOME)
        .reduce((acc, cur) => (acc + cur.amount), 0);
  }
  if (placedPayments && placedPayments.length > 0) {
    income += placedPayments.filter((payment: Payment) => payment.paymentType.id === PAYMENT_TYPE_INCOME)
        .reduce((acc, cur) => (acc + cur.amount), 0);
  }
  return income;
}

export const calcTotalExpenses = (pendingPayments: Payment[], placedPayments: Payment[]) => {
  let expenses = 0.0;
  if (pendingPayments && pendingPayments.length > 0) {
    expenses += pendingPayments.filter((payment: Payment) => payment.paymentType.id === PAYMENT_TYPE_EXPENSE)
        .reduce((acc, cur) => (acc + cur.amount), 0);
  }
  if (placedPayments && placedPayments.length > 0) {
    expenses += placedPayments.filter((payment: Payment) => payment.paymentType.id === PAYMENT_TYPE_EXPENSE)
        .reduce((acc, cur) => (acc + cur.amount), 0);
  }
  return expenses * -1;
}

export const getPendingPaymentsByMonth = (pendingPayments: Payment[]) => {
  const pendingPaymentsByMonth: Map<number, Payment[]> = new Map<number, Payment[]>;
  if (pendingPayments) {
    pendingPayments.forEach((payment: Payment) => {
      const month: number = payment.paymentDateAsDate.getMonth() + 1;
      if (pendingPaymentsByMonth.has(month)) {
        const payments: Payment[] = pendingPaymentsByMonth.get(month);
        payments.push(payment);
      } else {
        pendingPaymentsByMonth.set(month, [payment]);
      }
    });
  }
  return pendingPaymentsByMonth;
}

export const getPlacedPaymentsByMonth = (placedPayments: Payment[]) => {
  const placedPaymentsByMonth: Map<number, Payment[]> = new Map<number, Payment[]>;
  if (placedPayments) {
    placedPayments.forEach((payment: Payment) => {
      const month: number = payment.paymentDateAsDate.getMonth() + 1;
      if (placedPaymentsByMonth.has(month)) {
        const payments: Payment[] = placedPaymentsByMonth.get(month);
        payments.push(payment);
      } else {
        placedPaymentsByMonth.set(month, [payment]);
      }
    });
  }
  return placedPaymentsByMonth;
}

export const initPayment = (payment: Payment) => {
  if (!payment) {
    return;
  }

  if (payment.paymentDate) {
    payment.paymentDateAsDate = new Date(payment.paymentDate);
    payment.paymentDateFormatted = format(payment.paymentDateAsDate, DATE_FORMATS.DATE_ISO);
  } else {
    payment.paymentDateFormatted = null;
  }

  payment.deductible = payment.deductible ?? false;
  payment.intervalValue = payment.intervalValue ?? null;

  if (payment.paymentModeId === PAYMENT_MODE_RECURRING) {
    payment.updateFollowingPayments = true;
  }

  if (payment.createDate) {
    payment.createDateAsDate = new Date(payment.createDate);
  }

  if (payment.changeDate) {
    payment.changeDateAsDate = new Date(payment.changeDate);
  }

};

export const createNewPayment = (paymentCategories: PaymentCategory[], defaultDate: Date): Payment => {
  const paymentDate: Date = defaultDate ?? new Date();

  const payment: Payment = {
    id: null,
    paymentName: null,
    paymentDateFormatted: format(paymentDate, DATE_FORMATS.DATE_ISO),
    amount: null,
    deductible: false,
    intervalValue: null,
    paymentTypeId: PAYMENT_TYPE_EXPENSE,
    paymentCategoryId: paymentCategories && paymentCategories.length > 0 ? paymentCategories[0].id : null,
    paymentModeId: PAYMENT_MODE_ONCE,
    statusId: PaymentStatus.PLACED,
  };
  return payment;
}

export const convertToPayment = (formPayment: Payment): Payment => {

  if (formPayment.paymentDateFormatted) {
    formPayment.paymentDateAsDate = parse(formPayment.paymentDateFormatted, DATE_FORMATS.DATE_ISO, new Date());
    formPayment.paymentDate = format(formPayment.paymentDateAsDate, DATE_FORMATS.FULL_ISO);
  } else {
    formPayment.paymentDate = null;
  }

  const {
    id,
    paymentName,
    paymentDate,
    amount,
    deductible,
    intervalValue,
    paymentTypeId,
    paymentCategoryId,
    paymentModeId,
    paymentIntervalId,
    statusId,
    updateFollowingPayments
  } = formPayment;

  const payment: Payment = {
    id: id ? Number(id) : null,
    paymentName: paymentName || null,
    paymentDate: paymentDate || null,
    amount: amount ? Number(amount) : null,
    deductible: deductible ? Boolean(deductible) : null,
    // deliberately used "=="
    intervalValue: paymentModeId == PAYMENT_MODE_RECURRING && intervalValue ? Number(intervalValue) : null,
    paymentTypeId: paymentTypeId ? Number(paymentTypeId) : null,
    paymentCategoryId: paymentCategoryId ? Number(paymentCategoryId) : null,
    paymentModeId: paymentModeId ? Number(paymentModeId) : null,
    // deliberately used "=="
    paymentIntervalId: paymentModeId == PAYMENT_MODE_RECURRING && paymentIntervalId ? Number(paymentIntervalId) : null,
    statusId: statusId ? Number(statusId) : null,
    updateFollowingPayments: updateFollowingPayments ? Boolean(updateFollowingPayments) : null,
  }

  return payment;
}

export const convertPaymentToCsv = (payment: Payment, label: any): string => {
  const {
    id,
    paymentDate,
    paymentName,
    displayAmount,
    paymentCategory,
    paymentType,
    paymentMode,
    statusId,
  } = payment;

  const formattedAmount = displayAmount.toFixed(2).replace('.', ',');

  const values = [
    id,
    paymentDate,
    paymentName,
    formattedAmount,
    paymentCategory?.categoryName || '',
    paymentType?.typeName || '',
    paymentMode?.modeName || '',
    statusId ? label(getPaymentStatusLabel(statusId)) : '',
  ];

  return values.join(';');
}