import {PaymentType} from "../types/paymentType/PaymentType";
import {SelectItem} from "../types/_general/SelectItem";

export const getPaymentTypeItems = (paymentTypes: PaymentType[]): SelectItem[] => {
  const paymentTypeItems: SelectItem[] = paymentTypes.map((paymentType: PaymentType) => {
    const paymentTypeItem: SelectItem = {
      value: paymentType.id,
      label: paymentType.typeName,
    }
    return paymentTypeItem;
  });
  return paymentTypeItems;
}