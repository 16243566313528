import PaymentCategoryRow from "./PaymentCategoryRow";
import Spinner from "../../_general/Spinner";
import React from "react";
import useLabels from "../../../hooks/useLabels";
import labels from "../../../types/constants/labels";
import Table from "../../_general/Table";
import {PaymentCategory} from "../../../types/paymentCategory/PaymentCategory";

interface Props {
  paymentCategories: PaymentCategory[];
  isLoading: boolean;
  onInitShowEditModal: (show: boolean) => void;
  onInitShowDeleteModal: (show: boolean) => void;
  onInitEditPaymentCategory: (paymentCategory: PaymentCategory) => void;
  onInitDeletePaymentCategory: (paymentCategory: PaymentCategory) => void;
}
const PaymentCategoriesTable = ({ paymentCategories, isLoading,
                                onInitShowEditModal, onInitShowDeleteModal, onInitEditPaymentCategory, onInitDeletePaymentCategory}: Props) => {

  const { getLabel: label } = useLabels();

  if (isLoading) {
    return (
        <>
          <Spinner />
          <p className="text-muted">{label(labels.paymentCategories.titles.CATEGORIES_LOADED)}</p>
        </>
    );
  }
  if (!paymentCategories || paymentCategories.length === 0) {
    return (
        <p className="text-muted">{label(labels.paymentCategories.titles.NO_CATEGORIES_FOUND)}</p>
    );
  }

  return (
      <>
        <div className="table-container">
          <Table classes="table-striped table-clickable-rows">
            <thead>
            <tr>
              <th scope="col">{label(labels.paymentCategories.fields.ID)}</th>
              <th scope="col">{label(labels.paymentCategories.fields.CATEGORY_NAME)}</th>
              <th scope="col">{label(labels.paymentCategories.fields.COLOR)}</th>
              <th scope="col">{label(labels.paymentCategories.fields.CREATE_DATE)}</th>
              <th scope="col">{label(labels.paymentCategories.fields.CHANGE_DATE)}</th>
              <th></th>
            </tr>
            </thead>
            <tbody>
            {paymentCategories.map((paymentCategory: PaymentCategory) => (
                <PaymentCategoryRow
                    key={paymentCategory.id}
                    paymentCategory={paymentCategory}
                    onInitShowEditModal={onInitShowEditModal}
                    onInitShowDeleteModal={onInitShowDeleteModal}
                    onInitEditPaymentCategory={onInitEditPaymentCategory}
                    onInitDeletePaymentCategory={onInitDeletePaymentCategory}/>
            ))}
            </tbody>
          </Table>
        </div>
      </>
  );

}

export default PaymentCategoriesTable;