import NavBar from "../../components/_layout/NavBar";
import {Outlet, useLocation, useNavigate, useNavigation} from "react-router-dom";
import {useEffect} from "react";
import {AuthState} from "../../types/state/AuthState";
import {useSelector} from "react-redux";
import useLogin from "../../hooks/login/login";
import Footer from "../../components/_layout/Footer";
import useNav from "../../hooks/nav/nav";
import Header from "../../components/_layout/Header";
import Logo from "../../components/_layout/Logo";
import NavMenu from "../../components/_layout/NavMenu";

const RootLayout = () => {

  const { isLoggedIn, userData, language }: AuthState = useSelector((state: any) => state.auth);
  // const { projects, project, team }: ProjectsState = useSelector((state: any) => state.projects);

  const {
    logout,
  } = useLogin();
  const {
    // changeProject,
    // changeTeam,
    changeLanguage
  } = useNav();

  const navigate = useNavigate();
  const location = useLocation();

  // const teams: Team[] = project?.teams ?? [];

  useEffect(() => {
    if (location.pathname === '/') {
      if (isLoggedIn) {
        navigate('/payments');
      } else {
        navigate('/login');
      }
    };
  }, []);

  return (
      <>
        <div className="app-container">
          <Header>
            <Logo />
            <NavBar isLoggedIn={isLoggedIn} userData={userData}
                    language={language}
                    onLogout={logout}
                    onChangeLanguage={changeLanguage} />
          </Header>

          <NavMenu isLoggedIn={isLoggedIn} />

          <div className="main-wrapper">
            <main className="main">
              <Outlet />
            </main>

            <Footer />
          </div>
        </div>
      </>
  );
};

export default RootLayout;
