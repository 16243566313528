import React, {useEffect} from "react";
import {useForm} from "react-hook-form";
import Modal from "../../_general/Modal";
import Alert from "../../_general/Alert";
import {AlertData} from "../../../types/components/AlertData";
import Button from "../../_general/Button";
import {PaymentCategory} from "../../../types/paymentCategory/PaymentCategory";
import useLabels from "../../../hooks/useLabels";
import labels from "../../../types/constants/labels";
import {convertToPaymentCategory} from "../../../utils/paymentCategoryUtils";

interface Props {
  paymentCategory: PaymentCategory;
  alertData: AlertData;
  mode: string;
  modalId: string;
  showModal: boolean;
  isLoading: boolean;
  hasError: boolean;
  onInitShowModal: (show: boolean) => void;
  onSavePaymentCategory: (paymentCategory: PaymentCategory) => Promise<boolean>;
  onCancel: () => void;
}

const PaymentCategoryForm = ({ paymentCategory, alertData, mode, modalId, showModal,
                           isLoading, hasError, onInitShowModal, onSavePaymentCategory, onCancel }: Props) => {

  const { getLabel: label } = useLabels();

  const {
    register,
    handleSubmit: onSubmit,
    watch,
    formState: { errors },
    reset
  } = useForm<PaymentCategory>();

  useEffect(() => {
    reset(paymentCategory);
  }, [paymentCategory]);


  const handleSubmit = async (formPaymentCategory: PaymentCategory) => {
    const paymentCategory: PaymentCategory = convertToPaymentCategory(formPaymentCategory);

    const wasSuccess: boolean = await onSavePaymentCategory(paymentCategory);
    if (wasSuccess) {
      onInitShowModal(false);
    }

  }

  const handleCancel = () => {
    onCancel();
    onInitShowModal(false);
  }

  return (
      <>
          <Modal id={modalId} showModal={showModal}
                 title={mode === 'EDIT' ? label(labels.paymentCategories.actions.EDIT_CATEGORY) :
                          label(labels.paymentCategories.actions.NEW_CATEGORY)}
                 scrollable={true}
                 onCancel={handleCancel}>
            {isLoading &&
              <p>{label(labels.paymentCategories.titles.CATEGORY_LOADED)}</p>
            }

            {mode === 'EDIT' && !paymentCategory &&
              <p>{label(labels.paymentCategories.titles.NO_CATEGORY_FOUND)}</p>
            }

            {alertData &&
                <Alert alertData={alertData} />
            }

            {paymentCategory &&
              <form onSubmit={onSubmit(handleSubmit)}>
                <div className="modal-body">

                  {mode === 'EDIT' &&
                      <input type="hidden" id={`${modalId}-id`}
                             {...register("id", {})}
                             defaultValue={paymentCategory.id}
                             className="form-control"/>
                  }

                  <div className="form-group mb-3">
                    <label htmlFor={`${modalId}-categoryName`} className="form-label">
                      {label(labels.paymentCategories.fields.CATEGORY_NAME) + ' *'}
                    </label>
                    <input type="text" id={`${modalId}-categoryName`}
                           {...register("categoryName", {required: true, maxLength: 255, pattern: /^(?! ).*(?<! )$/})}
                           className={`form-control ${errors.categoryName ? 'is-invalid' : ''}`}
                           placeholder={label(labels.paymentCategories.fields.CATEGORY_NAME)} required/>
                    {errors.categoryName &&
                        <div className="invalid-feedback">
                          {label(labels.paymentCategories.fields.CATEGORY_NAME_INVALID)}
                        </div>
                    }
                  </div>

                  <div className="form-group mb-3">
                    <label htmlFor={`${modalId}-color`} className="form-label">
                      {label(labels.paymentCategories.fields.COLOR)}
                    </label>
                    <input type="text" id={`${modalId}-color`}
                           {...register("color", {required: false, maxLength: 255, pattern: /^(?! ).*(?<! )$/})}
                           className={`form-control ${errors.color ? 'is-invalid' : ''}`}
                           placeholder={label(labels.paymentCategories.fields.COLOR)}/>
                    {errors.color &&
                        <div className="invalid-feedback">
                          {label(labels.paymentCategories.fields.COLOR_INVALID)}
                        </div>
                    }
                  </div>

                </div>
                <div className="modal-footer">
                  <Button classes="btn-light mr-2" icon="x" label={label(labels.actions.CANCEL)}
                          onClick={handleCancel}/>
                  <Button type="submit" classes="btn-primary" icon="check" label={label(labels.actions.SAVE)}/>
                </div>
              </form>
            }
          </Modal>
      </>
  );
}

export default PaymentCategoryForm;