export const loadState = (): any => {
  try {
    const serializedState: string = localStorage.getItem('state');
    const state: any = JSON.parse(serializedState);
    console.log('State loaded from local storage.');
    return state;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const saveState = (state: any) => {
  try {
    const serializedState: string = JSON.stringify(state);
    localStorage.setItem('state', serializedState);
    console.log('State persisted to local storage.');
  } catch (error) {
    console.error(error);
  }
};